export const api = 'path-education';

export const basePath = {
  notifications: '/notifications',
  admin: '/admin',
  getUser: '/getUser',
  updateUser: '/updateUser',
  createLearner: '/learners',
  getSession: '/getSession',
  addSession: '/addSession',
  cancelSession: '/cancelSession',
  updateSession: '/updateSession',
  getTutorInvoices: '/billing/tutor/invoices',
  getParentInvoices: '/billing/parent/invoices',
  getAgreement: '/agreement',
};

export default {
  api,
  basePath,
};
