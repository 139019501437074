import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  notifications: {
    width: '100%',
  },
  alert: {
    marginBottom: 8,
  },
  noNotificationsMessage: {
    textAlign: 'center',
    padding: 24,
    backgroundColor: '#FFFFFF',
  },
}));

export default useStyles;
