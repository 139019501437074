import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import useNotificationBell from './hook/useNotificationBell';
import useStyles from './styles';

const NotificationBell = () => {
  const { unseen } = useNotificationBell();
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => {
      navigate('/notifications');
    }}
    >
      <Badge color="primary">
        {unseen.length === 0
          ? <NotificationsNoneIcon className={classes.none} />
          : <NotificationsActiveIcon className={classes.active} />}
      </Badge>
    </IconButton>
  );
};

export default NotificationBell;
