import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import {
  Button,
  FormContainer,
  FormTitle,
} from '~common/components';
import useStyles from './styles';

const UnverifiedCard = ({ handleSignOut }) => {
  const { classes } = useStyles();

  return (
    <FormContainer maxWidth="md">
      <div className={classes.container}>
        <FormTitle
          title="Thank you for your application"
          image="/logo.svg"
        />
        <Typography variant="body1" className={classes.body}>
          Hello, your application to join Path Education as a tutor is being verified, we
          will be in touch shortly.
        </Typography>
        <Button type="button" onClick={() => handleSignOut()}>Sign Out</Button>
      </div>
    </FormContainer>
  );
};

UnverifiedCard.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
};

export default UnverifiedCard;
