import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

const verifyTutorTemplate = ({ classes, notificationData, userType }) => {
  const {
    firstName,
    lastName,
    accepted,
    approver,
  } = notificationData;
  const status = accepted ? 'Accepted' : 'Declined';
  const date = dayjs(approver.requestTime).format('DD MMMM YYYY');
  const time = dayjs(approver.requestTime).format('HH:mm');

  const message = userType === 'admin'
    ? (
      <>
        <strong>{firstName} {lastName}</strong> account verification was{' '}
        <strong>{status}</strong> on <strong>{date}</strong> at <strong>{time}</strong>.
      </>
    )
    : (
      <>
        Your verification was <strong>{status}</strong> on{' '}
        <strong>{date}</strong> at <strong>{time}</strong>.
      </>
    );

  return (
    <>
      <Typography variant="body1" className={classes.author} component="div">
        <strong>Path Education</strong>
      </Typography>
      <Typography variant="body1" className={classes.message} component="div">
        {message}
      </Typography>
    </>
  );
};

export default verifyTutorTemplate;
