import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '~common/components';
import DataGrid from '../../../components/dataGrid';
import DataGridToolbarContainer from '../../../components/dataGrid/toolbar/toolbarContainer';
import DataGridToolbarQuickFilter from '../../../components/dataGrid/toolbar/toolbarQuickFilter';
import useAssignLearnersDialog from './hook/useAssignLearnersDialog';
import useStyles from './styles';
import columns from './columns';
import DataGridToolbarSpacer from '../../../components/dataGrid/toolbar/toolbarSpacer';
import DataGridToolbarButton from '../../../components/dataGrid/toolbar/toolbarButton';

const getToolbar = ({ isDirty, onUndoChanges }) => () => (
  <DataGridToolbarContainer>
    <DataGridToolbarQuickFilter />
    <DataGridToolbarSpacer />
    <DataGridToolbarButton onClick={onUndoChanges} disabled={!isDirty}>
      Undo changes
    </DataGridToolbarButton>
  </DataGridToolbarContainer>
);

const AssignLearnersDialog = ({
  id,
  open,
  onClose,
  onCancel,
  onSubmit,
}) => {
  const { classes } = useStyles();
  const {
    tutor,
    loading,
    selectedLearners,
    learners,
    onSelectionModelChange,
    onRowUpdate,
    onCloseCallback,
    onCancelCallback,
    onSubmitCallback,
    alert,
    isDirty,
    isValid,
    onUndoChanges,
  } = useAssignLearnersDialog({
    id,
    onClose,
    onCancel,
    onSubmit,
  });

  return (
    <div>
      <Dialog open={open} onClose={onCloseCallback} maxWidth="md" scroll="paper" fullWidth disableEscapeKeyDown>
        <DialogTitle className={classes.title}>
          Manage Learners
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onCloseCallback}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add or remove learners assigned to <strong>{tutor.firstName} {tutor.lastName}</strong>.
          </DialogContentText>
          <br />
          <DataGrid
            className={classes.dataGrid}
            density="compact"
            initialState={{
              sorting: {
                sortModel: [{ field: 'fullName', sort: 'asc' }],
              },
            }}
            loading={loading}
            rows={learners}
            columns={columns}
            getRowId={(row) => row.id}
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: getToolbar({ isDirty, onUndoChanges }),
            }}
            checkboxSelection
            processRowUpdate={onRowUpdate}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedLearners}
            disableColumnMenu
            autoHeight
            experimentalFeatures={{ newEditingApi: true }}
            disableSelectionOnClick
            onCellEditStop={(params, event) => {
              if (params.reason === 'escapeKeyDown') {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
          {alert && (
            <Alert severity={alert.severity} className={classes.alert}>{alert.message}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancelCallback}>Cancel</Button>
          <Button variant="contained" onClick={onSubmitCallback} disabled={!isValid}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AssignLearnersDialog.defaultProps = {
  onClose: () => { },
  onCancel: () => { },
  onSubmit: () => { },
};

AssignLearnersDialog.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AssignLearnersDialog;
