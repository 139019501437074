import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginLeft: 'auto',
  },
}));

export default useStyles;
