import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import { gridDateComparator } from '@mui/x-data-grid';

import { Button } from '~common/components';
import useStyles from './styles';
import DataGrid from '../../components/dataGrid';
import useManageUsers from './hooks/useManageUsers';

const getFilename = (filename) => filename.split('--').pop();

export const columns = [
  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 175,
    type: 'date',
    sortComparator: (a, b) => gridDateComparator(
      dayjs(a, 'MMMM DD, YYYY').valueOf(),
      dayjs(b, 'MMMM DD, YYYY').valueOf(),
    ),
    valueGetter: (params) => {
      const { createdAt } = params.row;
      return dayjs(createdAt).format('MMMM DD, YYYY');
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 100,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 100,
  },
  {
    field: 'emailAddress',
    headerName: 'Email Address',
    width: 250,
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 70,
  },
  {
    field: 'agreement',
    headerName: 'Agreement',
    width: 70,
    renderCell: (params) => (params.row.agreement === 'signed' ? (
      <span style={{
        fontWeight: '500',
      }}
      >{params.row.agreement}
      </span>
    ) : params.row.agreement),
  },
  {
    field: 'dbsUploadFileName',
    headerName: 'D.B.S Document',
    width: 200,
    renderCell: (params) => (params.row?.dbsUploadFileName?.length
      ? (
        <a
          href={`${import.meta.env.VITE_AWS_DOCS_URL}/${params.row?.verified ? 'verified' : 'unverified'}/${params.row.dbsUploadFileName}`}
          download
        >
          {getFilename(params.row.dbsUploadFileName)}
        </a>
      )
      : 'No document found'),
  },
  {
    field: 'cvUploadFileName',
    headerName: 'CV Document',
    width: 200,
    renderCell: (params) => (params.row?.cvUploadFileName?.length
      ? (
        <a
          href={`${import.meta.env.VITE_AWS_DOCS_URL}/${params.row?.verified ? 'verified' : 'unverified'}/${params.row.cvUploadFileName}`}
          download
        >
          {getFilename(params.row.cvUploadFileName)}
        </a>
      )
      : 'No document found'),
  },
];

const Dashboard = () => {
  const { classes } = useStyles();

  const {
    data, getUsers, loading, error, setSelectedUsers, verifyUsers, isVerifyDisabled, success,
  } = useManageUsers();

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headerBar}>
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          noWrap
          className={classes.heading}
        >
          Tutors
        </Typography>
        <Button
          className={classes.button}
          onClick={verifyUsers}
          disabled={isVerifyDisabled}
        >
          Verify Users
        </Button>
      </div>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
              {success && <Alert severity="success" className={classes.alert}>{success}</Alert>}
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'createdAt', sort: 'desc' }],
                  },
                }}
                density="compact"
                pageSize={20}
                rows={data}
                columns={columns}
                getRowClassName={(params) => !params.row.verified && classes.unverifiedRow}
                loading={loading}
                getRowId={({ id }) => id}
                checkboxSelection
                isRowSelectable={(params) => !params.row.verified && params.row.agreement === 'signed'}
                onSelectionModelChange={(id) => setSelectedUsers(id)}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
