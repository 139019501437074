import dayjs from 'dayjs';
import {
  number, string, object, ref, boolean, array, bool,
} from 'yup';

import dataLists from '../dataLists';

const { subjects } = dataLists;

export const errorMessages = {
  agreement: 'Please confirm you have agreed to the following',
  email: 'Must be a valid email',
  forgottenPasswordResetCode: 'Password reset code is a 6 digit number',
  lowercaseEmail: 'Email must be lowercase',
  minRequiredChars: 'This field must be at least 3 characters',
  passwordDoesNotMatch: 'Passwords do not match',
  passwordLowerCase: 'Password must contain at least 1 lower case letter',
  passwordMinRequiredChars: 'Password must be at least 8 characters',
  passwordNumber: 'Password must contain at least 1 number',
  passwordSpecialChar: 'Password must contain at least 1 special character',
  passwordUpperCase: 'Password must contain at least 1 upper case letter',
  requiredField: 'This field is required',
  uniqueEmailAddress: 'Learner must have a different email address to those previously entered',
};

const rules = {
  minRequiredChars: string()
    .required(errorMessages.requiredField).min(3, errorMessages.minRequiredChars),
};

const fieldValues = {
  academicYearRequired: object().required(errorMessages.requiredField),
  academicYear: object().nullable(),
  academicType: object(),
  academicValue: object(),
  addressLine1: string().min(3, errorMessages.minRequiredChars),
  addressLine1Required: rules.minRequiredChars,
  addressLine2: string().nullable(),
  agreement: boolean().oneOf([true], errorMessages.agreement),
  agreementVersionRequired: string().required(),
  avatar: string().nullable(),
  callDetails: string(),
  meetingDetails: string(),
  chargeToCustomerRateRequired: number().min(0).required(),
  city: string().min(3, errorMessages.minRequiredChars),
  cityRequired: rules.minRequiredChars,
  confirmPasswordRequired: string()
    .required(errorMessages.requiredField)
    .oneOf([ref('password')], errorMessages.passwordDoesNotMatch),
  contact: object({
    email: bool().default(true),
    text: bool().default(true),
  }),
  costToPathRateRequired: number().min(0).required(),
  countryName: object(),
  countryNameRequired: object().required(errorMessages.requiredField),
  currentSchool: string(),
  currentSchoolRequired: string().required(errorMessages.requiredField),
  dateOfBirth: string(),
  dateOfBirthRequired: string()
    .nullable()
    .test('is-date', 'Not a valid date', (value) => dayjs(value).isValid())
    .test('is-future', 'Not valid, date is in the future', (value) => dayjs(value).isBefore(dayjs()))
    .test('min-age', 'Sorry, too young for platform', (value) => dayjs(value).isBefore(dayjs().subtract(6, 'year')))
    .required(errorMessages.requiredField),
  description: string(),
  emailAddress: string().email(errorMessages.email),
  emailAddressRequired: string().required(errorMessages.requiredField).email(errorMessages.email),
  firstName: string().min(3, errorMessages.minRequiredChars),
  firstNameRequired: rules.minRequiredChars,
  freeTrial: boolean(),
  idRequired: string().required(),
  lastName: string().min(3, errorMessages.minRequiredChars),
  lastNameRequired: rules.minRequiredChars,
  passwordRequired: string()
    .required(errorMessages.requiredField)
    .min(8, errorMessages.passwordMinRequiredChars)
    .matches(/[a-z]+/, errorMessages.passwordLowerCase)
    .matches(/[A-Z]+/, errorMessages.passwordUpperCase)
    .matches(/.*[\^$*.[\]{}()?"!@#%&\/\\,><':;|_~`=+\- ]{1,}.*/, errorMessages.passwordSpecialChar) // eslint-disable-line
    .matches(/\d+/, errorMessages.passwordNumber),
  phoneNumber: number().typeError('Must be a number').positive().integer(),
  phoneNumberPrefix: object(),
  phoneNumberPrefixRequired: object().required(errorMessages.requiredField),
  phoneNumberRequired: number().typeError('Must be a number')
    .required(errorMessages.requiredField).positive()
    .integer(),
  postCode: string(),
  postCodeRequired: string().required(errorMessages.requiredField),
  previousSchools: array().of(string()),
  qualificationLevelRequired: object().required(errorMessages.requiredField),
  resetCodeRequired: string()
    .required(errorMessages.requiredField)
    .min(6, errorMessages.forgottenPasswordResetCode),
  subjectNameRequired: string().required(),
  subjectRequired: object({
    subjectName: string().required(errorMessages.requiredField),
    id: number().required(errorMessages.requiredField),
  }).required(errorMessages.requiredField),
  titleRequired: string().required(),
  uuidRequired: string()
    .required()
    .test('uuid-not-strict', 'Failed to validate uuid', (value) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value)),
  verifyUserRequired: boolean().required(),
};

const fields = {
  ...fieldValues,
  contactRequired: object({
    email: bool().default(true).required(),
    text: bool().default(true).required(),
  }).required(),
  degree: object().shape({
    level: string(),
    subject: string(),
    university: string(),
  }),
  extraDegrees: array().of(object().shape({
    level: string().required(errorMessages.requiredField),
    subject: string().required(errorMessages.requiredField),
    university: string().required(errorMessages.requiredField),
  })),
  degreeRequired: object().shape({
    level: string().required(errorMessages.requiredField),
    subject: string().required(errorMessages.requiredField),
    university: string().required(errorMessages.requiredField),
  }).required(),
  lessonDurationRequired: object().shape({
    name: string().required(),
    value: number().required(),
  }),
  school: object().shape({
    name: string(),
    current: boolean(),
  }),
  schoolRequired: object().shape({
    name: string().required(errorMessages.requiredField),
    current: boolean().required(errorMessages.requiredField),
  }).required(),
  subjectList: array().of(object().shape({
    subject: object(),
    qualificationLevel: object(),
    version: number().default(1),
    examBoard: object().nullable(),
  })),
  subjectListRequired: array().of(object().shape({
    subject: fieldValues.subjectRequired,
    version: number().default(1),
    qualificationLevel: fieldValues.qualificationLevelRequired,
    examBoard: object().required(errorMessages.requiredField),
  })).test('validate-exam-boards', 'Exam board is required for this subject', (value) => {
    console.log('55555', value);

    if (!value) return true; // If no value, other validators will catch this

    return value.every((item) => {
      console.log('221112', item);
      if (item.version !== 2) return true; // Only apply this validation for version 2

      const qualificationLevel = subjects.find((q) => q.id === item.qualificationLevel.id);
      if (!qualificationLevel) return true; // If qualification not found, other validators will catch this

      const subject = qualificationLevel.subjects.find((s) => s.id === item.subject.id);
      if (!subject) return true; // If subject not found, other validators will catch this

      console.log('222', item);
      // If the subject has exam boards and the list is not empty, require an exam board selection
      if (qualificationLevel.examBoards && qualificationLevel.examBoards.length > 0) {
        return item.examBoard && item.examBoard.id && item.examBoard.examBoardName;
      }

      return true;
    });
  }),
};

export default fields;
