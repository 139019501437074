/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { config } from '../../../utils';
import useStyles from './styles';

dayjs.extend(advancedFormat);

const ParentAgreement = ({ data }) => {
  const { classes } = useStyles();
  const date = dayjs(data.createdAt) ?? dayjs();

  return (
    <Box className={classes.paper}>
      <h1 className={classes.header}>Parent and/or Guardian Agreement</h1>
      <p><strong>Version:</strong> {config.parentalAgreementVersion}</p>
      <p>THIS AGREEMENT is effective from today&apos;s date, {date.format('dddd MMMM Do, YYYY')}.</p>
      <p>Dear {data.firstName} {data.lastName} - Parent and/or Guardian,</p>
      <p>Firstly, we would like to welcome you to Path Education and thank you for entrusting our services. We are proud to be part of your child’s educational journey and are always on hand to offer our support and guidance to you as a family. Please feel free to contact our admin team to seek advice or ask questions at any time - we are here to help.</p>
      <p className={classes.subTitle}>Data Protection</p>
      <p className={classes.text}>We take data protection and GDPR compliance very seriously and we are committed to ensuring the privacy and security of our clients&#39; personal information.
      </p>
      <p className={classes.text}>Therefore, we will only collect, use and store personal data in accordance with the General Data Protection Regulation (GDPR) and other relevant data protection legislation.
      </p>
      <p className={classes.text}>Our clients have the right to access, rectify, erase, and restrict processing of their personal data. They also have the right to object to processing and to data portability. We will only use clients&#39; personal data for the purpose of providing our educational services and will not share it with third parties without their explicit consent, unless required by law.
      </p>
      <p className={classes.text}>By using our services, our clients agree to the terms of this data protection and GDPR clause.</p>
      <p className={classes.subTitle}>Parental and/or Guardian Consent</p>
      <p className={classes.text}>In order for your child to participate in Path Education’s services, we request your consent and involvement in helping them have a safe and productive experience.</p>
      <p className={classes.text}>Lessons will be conducted principally over Teams, Zoom or Skype (or similar software) and the arrangement of
        which may involve Path and your allocated tutors to contact your child via email and/or mobile. If your child is
        under the age of 18, we require your consent to allow us to contact them using the contact details provided.
      </p>
      <p className={classes.text}>By agreeing to the terms of this agreement, you give Path Education permission to contact your child as per the terms stated above.</p>
      <p className={classes.subTitle}>Booking</p>
      <p className={classes.text}>Sessions can be arranged using the WhatsApp group chat or by contacting the tutor or Path Education directly. Bookings are then confirmed on the platform.</p>
      <p className={classes.subTitle}>Sessions</p>
      <p className={classes.text}>Number of Sessions - There is no minimum requirement for the number of sessions.</p>
      <p className={classes.text}>Examination and Holiday Periods - If additional sessions are required leading up to an examination or school holiday period, please inform Path Education and/or the tutor in advance so that any additional support can be accommodated.</p>
      <p className={classes.text}>Session Times - Session times are normally confirmed week to week or at the end of each lesson to ensure as much flexibility as possible. Some students prefer to have the same time every week and this will be accommodated where possible.</p>
      <p className={classes.subTitle}>Referrals</p>
      <p className={classes.text}>We are very grateful for any referrals made by the families we support. In the event that you refer a family or friend to Path Education, the referral should be directed to Path Education and not to individual tutors.</p>
      <p className={classes.subTitle}>Additional Support</p>
      <p className={classes.text}>Similarly, any requests for additional support should be directed to Path Education and not individual tutors. In the event that families attempt to approach tutors directly, Path Education may withdraw the provision of their services.</p>
      <p className={classes.subTitle}>Lateness</p>
      <p className={classes.text}>If there is a possibility that the student may be late to a session, please let the tutor know as soon as you can. Should the student be over fifteen minutes late without prior notice, the tutor may cancel the lesson.</p>
      <p className={classes.subTitle}>Cancellations</p>
      <p className={classes.text}>If a lesson is not cancelled with at least 12 hours’ notice, the full lesson fee will still be charged. Please provide as much notice as possible so that tutors can use the scheduled time for other students. Tutors reserve the right to waiver the cancellation fee if the circumstances are due to illness supported by a doctor’s note or other extenuating circumstances.</p>
      <p className={classes.subTitle}>Invoices</p>
      <p className={classes.text}>Invoices are issued a month in arrears and are settled online using Stripe, our payment management provider.
        We do not store any payment data on any Path owned or operated platform. All payment data is only stored on
        Stripe’s secure platform according to their rules and policies which can be found here. https://stripe.com/privacy.
      </p>
      <p className={classes.text}>I have read the above agreement and agree to the terms.</p>
    </Box>
  );
};

ParentAgreement.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default ParentAgreement;
