import { useNotificationsStore } from '~common/store';

export const notificationsSelector = (state) => ({
  notifications: state.notifications,
  getNotifications: state.getNotifications,
  deleteNotifications: state.deleteNotifications,
  postNotificationsSeen: state.postNotificationsSeen,
});

export default useNotificationsStore;
