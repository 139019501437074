import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

import { NotificationList } from '~common/components';
import useStyles from './styles';
import useNotificationsList from './hooks/useNotificatonsList';

function Notifications({ userData }) {
  const { classes } = useStyles();
  const {
    notifications,
    handleDeleteNotifications,
    handlePostNotificationsSeen,
  } = useNotificationsList();

  useEffect(() => {
    handlePostNotificationsSeen();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.header}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
          >
            Notifications
          </Typography>
          <Typography variant="body2">
            <span className={classes.dayTitle}>{dayjs().format('dddd')}</span>
            <span>{dayjs().format(', D MMMM YYYY')}</span>
          </Typography>
        </div>
        <NotificationList
          user={userData}
          notifications={notifications}
          onNotificationDelete={handleDeleteNotifications}
          avatarUriPrefix={`${import.meta.env.VITE_AWS_AVATAR_URL}`}
        />
      </div>
    </div>
  );
}

Notifications.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
};

export default Notifications;
