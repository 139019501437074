import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LessonForm } from '~common/components';
import { dataLists } from '~common/utils';
import { getLearnersList, getSubjectList } from '~common/components/lessonForm/helpers';
import { formatSession } from '~common/store/sessionsStore';
import useStyles from './styles';

const { lessonDurationOptions } = dataLists;
const EditLessonDialog = ({
  open,
  onClose,
  selectedSession,
  tutor,
  commitChanges,
  setAppointmentFormVisible,
}) => {
  const { classes } = useStyles();
  const learnersList = getLearnersList(tutor.learners);
  const subjectList = getSubjectList(tutor.subjectList);
  const appointmentProps = {
    appointmentData: formatSession(selectedSession),
    lessonDurationOptions,
    learnersList,
    subjectList,
    commitChanges,
    setAppointmentFormVisible,
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md" scroll="paper" fullWidth disableEscapeKeyDown>
        <DialogTitle className={classes.title}>
          Edit Lesson
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LessonForm appointmentProps={appointmentProps} allowCancel={false} isAdmin />
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditLessonDialog.defaultProps = {
  onClose: () => { },
};

EditLessonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  selectedSession: PropTypes.shape({}).isRequired,
  tutor: PropTypes.shape({
    learners: PropTypes.arrayOf(PropTypes.shape({})),
    subjectList: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  commitChanges: PropTypes.func.isRequired,
  setAppointmentFormVisible: PropTypes.func.isRequired,
};

export default EditLessonDialog;
