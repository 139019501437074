import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import isEqual from 'lodash/isEqual';
import {
  getUsers,
  getUserProfile,
  assignLearners,
  getTutorBilling,
} from '~common/api';

const alerts = {
  undo: {
    severity: 'info',
    message: 'Learner assignments reset',
  },
  errorTutorProfile: {
    severity: 'error',
    message: 'There was an error attempting to retrieve tutor details, please try again later',
  },
  errorTutorBilling: {
    severity: 'error',
    message: 'There was an error attempting to retrieve tutor billing details, please try again later',
  },
  errorGetLearners: {
    severity: 'error',
    message: 'There was an error attempting to retrieve learners, please try again later',
  },
  warnSetRates: {
    severity: 'warning',
    message: 'Remember to set Customer Charge and Tutor Cost for learner',
  },
  errorInvalidLearners: {
    severity: 'error',
    message: 'One or mode learners are missing Customer Charge and/or Tutor Cost values',
  },
};

const isSelectionValid = (
  selectedLearners,
  defaultSelectedLearners,
) => isEqual(selectedLearners, defaultSelectedLearners)
  || Object
    .entries(selectedLearners ?? {})
    .every(([, learner]) => (
      learner.chargeToCustomerRate > 0
      && learner.costToPathRate > 0
      && learner.chargeToCustomerRate !== 'Double click to set'
      && learner.costToPathRate !== 'Double click to set'
    ));

const useAssignLearnersDialog = ({
  id,
  onClose,
  onCancel,
  onSubmit,
}) => {
  const [state, setState] = useState({
    tutor: {},
    learners: null,
    selectedLearners: null,
    defaultLearners: null,
    defaultSelectedLearners: null,
    loading: true,
    isDirty: false,
    alert: null,
  });

  const fetchData = async () => {
    const [tutor, getProfileErr] = await getUserProfile({ id });
    if (getProfileErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorTutorProfile,
      }));

      return;
    }

    const [tutorBilling, getTutorBillingErr] = await getTutorBilling({ id });
    if (getTutorBillingErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorTutorBilling,
      }));

      return;
    }

    const [learners, getUsersErr] = await getUsers({
      attributes: [
        'id',
        'firstName',
        'lastName',
        'emailAddress',
        'ownAccount',
        'contact',
        'parent',
        'subjectList',
      ],
      userTypes: [
        'learner',
      ],
    });
    if (getUsersErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorGetLearners,
      }));

      return;
    }

    const formattedLearners = learners?.results?.reduce((acc, learner) => {
      const formattedLearner = {
        ...learner,
        ...tutorBilling.learners?.[learner.id] ?? {
          chargeToCustomerRate: learner.chargeToCustomerRate ?? 'Double click to set',
          costToPathRate: learner?.costToPathRate ?? 'Double click to set',
        },
      };

      acc.learners[learner.id] = formattedLearner;

      if (tutor.learners?.findIndex((l) => l.id === formattedLearner.id) > -1) {
        acc.selectedLearners[formattedLearner.id] = formattedLearner;
      }

      return acc;
    }, { learners: {}, selectedLearners: {} });

    setState((prevState) => ({
      ...prevState,
      ...formattedLearners,
      tutor,
      defaultLearners: formattedLearners.learners,
      defaultSelectedLearners: formattedLearners.selectedLearners,
      loading: false,
    }));
  };

  useEffect(() => {
    const getData = async () => fetchData();
    getData();
  }, []);

  const learners = useMemo(
    () => Object.entries(state.learners ?? {}).map(([, entry]) => entry),
    [state.learners],
  );

  const selectedLearners = useMemo(
    () => Object.keys(state.selectedLearners ?? {}),
    [state.selectedLearners],
  );

  const onUndoChanges = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      learners: prevState.defaultLearners,
      selectedLearners: prevState.defaultSelectedLearners,
      alert: alerts.undo,
      isDirty: false,
    }));
  }, []);

  const onSelectionModelChange = useCallback((selectionModel) => {
    const selection = selectionModel.reduce((acc, learnerId) => ({
      ...acc,
      [learnerId]: state.learners[learnerId],
    }), {});

    setState((prevState) => ({
      ...prevState,
      selectedLearners: selection,
      isDirty: true,
      alert: alerts.warnSetRates,
    }));
  }, [state.learners]);

  const onRowUpdate = useCallback(async (row) => {
    const { id: learnerId, chargeToCustomerRate, costToPathRate } = row;

    setState((prevState) => ({
      ...prevState,
      learners: {
        ...prevState.learners,
        [learnerId]: {
          ...prevState.learners[learnerId],
          chargeToCustomerRate,
          costToPathRate,
        },
      },
      ...(prevState.selectedLearners[learnerId] && {
        selectedLearners: {
          ...prevState.selectedLearners,
          [learnerId]: {
            ...prevState.selectedLearners[learnerId],
            chargeToCustomerRate,
            costToPathRate,
          },
        },
      }),
      isDirty: true,
    }));

    return row;
  }, [state.learners]);

  const onCloseCallback = useCallback(async () => {
    setState(({
      tutor: null,
      learners: null,
      selectedLearners: null,
      loading: true,
      isDirty: false,
    }));

    onClose();
  }, []);

  const onCancelCallback = useCallback(async () => {
    setState({
      tutor: null,
      learners: null,
      selectedLearners: null,
      loading: true,
      isDirty: false,
    });

    onCancel();
  }, []);

  const onSubmitCallback = useCallback(async () => {
    if (!isSelectionValid(state.selectedLearners, state.defaultSelectedLearners)) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorInvalidLearners,
      }));

      return;
    }

    const result = await assignLearners({
      id: state.tutor.id,
      learners: Object.entries(state.selectedLearners ?? {})
        .reduce((acc, [, learner]) => ([...acc, learner]), []),
    });

    if (result.isOk) {
      setState((prevState) => ({
        ...prevState,
        isDirty: false,
        defaultSelectedLearners: prevState.selectedLearners,
      }));
    }

    onSubmit(result.isOk);
  }, [state.selectedLearners]);

  return {
    ...state,
    selectedLearners,
    learners,
    onSelectionModelChange,
    onRowUpdate,
    onCloseCallback,
    onCancelCallback,
    onSubmitCallback,
    onUndoChanges,
    isValid: isSelectionValid(state.selectedLearners, state.defaultSelectedLearners),
  };
};

export default useAssignLearnersDialog;
