import { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Close from '@mui/icons-material/Close';
import {
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '../../../button';
import AvatarImage from '../../../AvatarImage';
import useStyles from './styles';

const UserDropdownMenu = ({
  handleSignOut,
  email,
  username,
  userType,
  avatar,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const profileUrl = '/profile';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleNavClick = () => {
    navigate(profileUrl);
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.button}
        variant="text"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <AvatarImage className={classes.accountIcon} size="m" src={avatar} />
      </Button>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        <div className={classes.menuName}>
          <Typography variant="body1" className={classes.username}>{username}</Typography>
          <Typography variant="body2" className={classes.email}>{email}</Typography>
        </div>
        <Divider sx={{ my: 0.5 }} />
        {userType !== 'admin' && (
          <MenuItem onClick={() => navigate(profileUrl)} disableRipple>
            <AvatarImage className={classes.accountIcon} size="s" src={avatar} />
            <Typography variant="body2" className={classes.text}>Profile</Typography>
          </MenuItem>
        )}
        {userType !== 'admin' && (<Divider sx={{ my: 0.5 }} />)}
        <MenuItem onClick={handleSignOut} disableRipple>
          <Typography variant="body1" className={classes.logout}>LOG OUT</Typography>
        </MenuItem>
      </Menu>
      <Drawer
        variant="temporary"
        className={classes.navDrawer}
        sx={{ display: { xs: 'block', sm: 'none' } }}
        open={open}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            onClick={handleClose}
            size="large"
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </Toolbar>
        <div className={classes.menuName}>
          <Typography variant="body1" className={classes.username}>{username}</Typography>
          <Typography variant="body2" className={classes.email}>{email}</Typography>
        </div>
        <Divider sx={{ my: 0.5 }} />
        {userType !== 'admin' && (
          <MenuItem onClick={handleNavClick} disableRipple>
            <AvatarImage className={classes.accountIcon} size="s" src={avatar} />
            <Typography variant="body2" className={classes.text}>Profile</Typography>
          </MenuItem>
        )}
        {userType !== 'admin' && (<Divider sx={{ my: 0.5 }} />)}
        <MenuItem onClick={handleSignOut} disableRipple>
          <Typography variant="body1" className={classes.logout}>LOG OUT</Typography>
        </MenuItem>
      </Drawer>
    </>
  );
};

UserDropdownMenu.defaultProps = {
  username: '',
  avatar: null,
};

UserDropdownMenu.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  username: PropTypes.string,
  avatar: PropTypes.string,
  userType: PropTypes.string.isRequired,
};

export default UserDropdownMenu;
