import learnerSignupTemplate from './learnerSignup';
import parentSignupTemplate from './parentSignup';
import sessionTemplate from './session';
import tutorSignupTemplate from './tutorSignup';
import verifyTutorTemplate from './verifyTutor';

export default {
  sessionAdd: sessionTemplate,
  sessionUpdate: sessionTemplate,
  sessionDelete: sessionTemplate,
  sessionCancel: sessionTemplate,
  tutorSignup: tutorSignupTemplate,
  parentSignup: parentSignupTemplate,
  learnerSignup: learnerSignupTemplate,
  verifyTutor: verifyTutorTemplate,
};
