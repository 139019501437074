/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { config } from '../../../utils';
import useStyles from './styles';

dayjs.extend(advancedFormat);

const TutorAgreement = ({ data }) => {
  const { classes } = useStyles();
  const date = dayjs(data.createdAt) ?? dayjs();

  return (
    <Box className={classes.paper}>
      <h1 className={classes.header}>Tutor Agreement</h1>
      <p><strong>Version:</strong> {config.tutorAgreementVersion}</p>
      <p>THIS AGREEMENT is effective from today&apos;s date, {date.format('dddd MMMM Do, YYYY')}.</p>

      <p>BETWEEN:</p>
      <ol className={classes.listUnstyled}>
        <li>(1) You, {data.firstName} {data.lastName} (the “Tutor”); and</li>
        <li>(2) Path Education Limited, whose registered office is situated at 311 Regents Park Road, London, England, N3 1DP, with company number 09782954  (“Path”).</li>
      </ol>

      <h4>WHEREAS:</h4>
      <ul className={classes.unorderedList}>
        <li>(A) Path has appointed the Tutor to provide tutoring and/or mentoring services (the “<strong>Services</strong>”) to Path customers; and</li>
        <li>(B) Path has agreed to pay the Tutor the amounts agreed for the Services.</li>
      </ul>

      <h4>Interpretation</h4>
      <p>The definitions and rules of interpretation in this clause apply in this agreement.</p>
      <p><strong>Capacity</strong>: as agent, consultant, director, employee, worker, owner, partner or shareholder.</p>
      <p><strong>Path Customer</strong>: customer or prospective customer of Path with whom the Tutor has contact or about whom the Tutor becomes aware or informed of in the course of carrying out the Services.</p>
      <p><strong>Restricted Person</strong>: anyone engaged by Path as a tutor or provider of Services who could materially damage our interests if they were involved in any Capacity in any business concern which competes with any business interests of Path.</p>
      <p><strong>Services</strong>: means tutoring or other educational services which the Tutor provides to Path or Path Customers from time to time.</p>

      <p>The parties hereby agree as follows:</p>

      <h4>1. Tutor Undertakings</h4>
      <p>In consideration of the payments from Path made to the Tutor for the Services, the Tutor warrants to and undertakes to Path that:</p>
      <ol className={classes.listUnstyled}>
        <li>1.1 &emsp; they shall exercise all reasonable skill, care and diligence in their performance of the Services;</li>
        <li>1.2 &emsp; they shall use their best endeavours to protect and further the reputation, brand and good standing of Path;</li>
        <li>1.3 &emsp; they shall not deviate in any material way from the nature of the Services agreed with Path without prior consent from Path;</li>
        <li>1.4 &emsp; they shall, upon request from Path, provide Path with a written report on the Services within a reasonable time period not later than five working days after such request is made;</li>
        <li>1.5 &emsp; they shall not terminate, seek to terminate, treat the same as having been repudiated or discontinue the performance of its Services in each case without the prior written consent of Path; and</li>
        <li>1.6 &emsp; they shall not enter into any discussion with any Path Client in relation to fees, hourly rates or costs of the Services.</li>
        <li>1.7 &emsp; they shall provide their UTR number and be responsible for declaring any income received from Path Education to HMRC.</li>
        <li>1.8 &emsp; in the event of a lesson cancellation with less than 12 hours&apos; notice, they can use their discretion to charge a cancellation fee. In this event they shall be remunerated by Path Education for half of the booked lesson time.</li>
        <li>1.9 &emsp; they shall send their monthly timesheet to Path Education on the last day of each month which lessons took place. If timesheets are sent on or before the last day of each month, corresponding payments to tutors will be made within the first 10 working days of the following month.</li>
        <li>1.10 &emsp; if timesheets are sent to Path more than two months after lessons took place, Path is unable to guarantee the payment</li>
      </ol>

      <h4>2. Assignment</h4>
      <p>The Tutor shall not be entitled to assign or transfer, or otherwise dispose of all or any of its rights or liabilities arising under this Agreement.</p>

      <h4>3. Confidentiality</h4>
      <p>At any time during the provision of the Services, the Tutor must not, without the prior written consent of Path Education, disclose or make use of any confidential information (such as details of clients and their requirements, terms of business, rates and remuneration, marketing plans, forecasts and financial information) relating or belonging to Path Education or any of its affiliates, or their customers or clients.</p>

      <h4>4. Non-Compete</h4>
      <p>In order to protect Path and its business connections to which the Tutor has access as a result of providing the Services, the Tutor hereby covenants with Path that:</p>

      <ol className={classes.list}>
        <li>while providing the Services and for two years after the Tutor ceases to provide the Services, the Tutor shall not solicit or endeavour to entice away from Path any business or custom of a Path Customer with a view to providing any services to that Path Customer in competition with Path;</li>
        <li>while providing the Services and for two years after the Tutor ceases to provide the Services, the Tutor shall not employ or engage or otherwise endeavour to entice away from Path any Restricted Person;</li>
        <li>while providing the Services and for two years after the Tutor ceases to provide the Services, the Tutor undertakes not to be involved with the provision of any educational services to, or otherwise have any business dealings with, any Path Customer in the course of any business concern which is in competition with Path;</li>
        <li>while providing the Services and for two years after the Tutor ceases to provide the Services, the Tutor undertakes to:</li>
      </ol>
      <ol className={classes.listUnstyled}>
        <li>4.1 &emsp; recommend Path and recommend no other provider of educational services in competition with Path to any Path Customer who requires or expresses any interest in any educational services in addition to the Services; and</li>
        <li>4.2 &emsp; inform Path without delay if any Path Customer requires or expresses any interest in the provision of any educational services in addition to the Services.</li>
      </ol>

      <p>The restrictions imposed on the Tutor by this clause 3 apply to the Tutor acting directly or indirectly; and on their own behalf or on behalf of, or in conjunction with, any firm, company or person.</p>

      <h4>5. Miscellaneous</h4>
      <ol className={classes.listUnstyled}>
        <li>5.1 The provisions of this Agreement shall be without prejudice to any other right of action that Path may have in tort or otherwise.</li>
        <li>5.2 This Agreement may be executed in any number of counterparts, all of which, taken together, shall constitute one and the same Agreement and any party may enter into this Agreement by executing a counterpart.</li>
        <li>5.3 The provisions of this Agreement shall remain in full force and effect notwithstanding the termination of the Services.</li>
        <li>5.4 A person who is not a party to this Agreement shall have no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any of its terms.</li>
        <li>5.5 No failure or delay by any party in exercising any right, power or privilege under this Agreement shall impair such right, power or privilege or be construed as a waiver thereof nor shall any single or partial exercise of any right, power or privilege preclude any other or further exercise thereof or the exercise of any other right, power or privilege.</li>
        <li>5.6 No waiver of any of the provisions of this Agreement shall be effective unless it is expressly stated to be a waiver and communicated to the relevant party in writing.</li>
        <li>5.7 This Agreement may only be varied or amended with the written agreement of all of the parties to it.</li>
      </ol>

      <h4>6. Law and Jurisdiction</h4>
      <p>This Agreement shall be governed by English Law and be subject to the exclusive jurisdiction of the English Courts. IN WITNESS whereof this Agreement has been executed by or on behalf of the parties the day and year first above written.</p>
    </Box>
  );
};

TutorAgreement.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default TutorAgreement;
