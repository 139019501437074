import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  header: {
    marginBottom: '30px',
  },
  alert: {
    marginBottom: 10,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  background: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dataGrid: {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
  },
}));
