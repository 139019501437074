import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import useStyles from './styles';

const PageTitle = ({ title }) => {
  const { classes } = useStyles();

  return (
    <div>
      <Typography
        component="h1"
        variant="h5"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography variant="body2">
        <span className={classes.dayTitle}>{dayjs().format('dddd')}</span>
        <span>{dayjs().format(', D MMMM YYYY')}</span>
      </Typography>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
