import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  label: {
    paddingBottom: 8,
    color: '#162C5F',
    fontWeight: 500,
    fontSize: 16,
  },
});

export default useStyles;
