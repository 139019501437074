import { useCallback, useEffect } from 'react';
import useNotificationsStore, { notificationsSelector } from '../../../store/notificationsStore';

const useNotificationsList = () => {
  const {
    notifications,
    getNotifications,
    deleteNotifications,
    postNotificationsSeen,
  } = useNotificationsStore(notificationsSelector);

  const handleGetNotifications = useCallback(
    async () => getNotifications(),
    [getNotifications],
  );
  const handleDeleteNotifications = useCallback(
    async (notificationId) => deleteNotifications(notificationId),
    [notifications],
  );
  const handlePostNotificationsSeen = useCallback(
    async () => postNotificationsSeen(),
    [notifications],
  );

  useEffect(() => {
    handleGetNotifications();
  }, [handleGetNotifications]);

  return {
    notifications,
    handleGetNotifications,
    handleDeleteNotifications,
    handlePostNotificationsSeen,
  };
};

export default useNotificationsList;
