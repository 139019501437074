import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

const FormDateTimePicker = ({
  control, name, rules, inputProps, readOnly,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDateTimePicker
          renderInput={(params) => <TextField {...params} error={Boolean(error)} />}
          value={value}
          onChange={onChange}
          inputFormat="DD/MM/YYYY hh:mm A"
          readOnly={readOnly}
          {...inputProps}
        />
      </LocalizationProvider>
    )}
  />
);

FormDateTimePicker.defaultProps = {
  rules: {},
  inputProps: {},
  readOnly: false,
};

FormDateTimePicker.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};

export default FormDateTimePicker;
