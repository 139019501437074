import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Drawer,
  Icon,
  IconButton,
  Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import NavListItems from './components/navListItems';
import UserDropdownMenu from './components/userDropdownMenu';
import NotificationBell from './components/NotificationBell';
import AssumedModeIndicator from './components/assumedModeIndicator';

const BasePageTemplate = ({
  userData,
  isAssumedMode,
  onSignout,
  navigationItems,
  classes,
  children,
  userListPanel,
  mobileNavOpen,
  handleMobileNavToggle,
}) => (
  <Box className={classes.box}>
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.appToolbar}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleMobileNavToggle}
          className={classes.menuIcon}
        >
          <MenuIcon />
        </IconButton>
        {isAssumedMode && (
        <AssumedModeIndicator userData={userData} isAssumedMode={isAssumedMode} />
        )}
        <div>
          <NotificationBell />
          <UserDropdownMenu
            handleSignOut={onSignout}
            email={userData.emailAddress}
            username={userData.fullName}
            userType={userData.userType}
            avatar={userData.avatar}
          />
        </div>
      </Toolbar>
    </AppBar>

    {/* Mobile view */}
    <Drawer
      variant="temporary"
      className={classes.navDrawer}
      sx={{ display: { xs: 'block', md: 'none' } }}
      open={mobileNavOpen}
      onClose={handleMobileNavToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Toolbar className={classes.toolBar}>
        <Icon className={classes.icon}>
          <img src="/patheducation-logo.svg" alt="patheducation-logo" />
        </Icon>
        <IconButton
          onClick={handleMobileNavToggle}
          size="large"
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      </Toolbar>
      <div>
        <NavListItems navLinkItems={navigationItems} onClick={handleMobileNavToggle} />
        {userListPanel && userListPanel}
      </div>
    </Drawer>

    {/* Desktop view */}
    <Drawer
      variant="permanent"
      className={classes.navDrawer}
      sx={{ display: { xs: 'none', md: 'block' } }}
      open
    >
      <Toolbar>
        <Icon className={classes.icon}>
          <img src="/patheducation-logo.svg" alt="patheducation-logo" />
        </Icon>
      </Toolbar>
      <NavListItems navLinkItems={navigationItems} />
    </Drawer>

    <Box component="main" id="main-container" className={classes.container}>
      {children}
    </Box>
  </Box>
);

BasePageTemplate.defaultProps = {
  isAssumedMode: false,
  userListPanel: null,
  mobileNavOpen: false,
  handleMobileNavToggle: null,
};

BasePageTemplate.propTypes = {
  userData: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    userType: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    box: PropTypes.string.isRequired,
    appBar: PropTypes.string.isRequired,
    appToolbar: PropTypes.string.isRequired,
    navDrawer: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    toolBar: PropTypes.string.isRequired,
    menuIcon: PropTypes.string.isRequired,
    closeIcon: PropTypes.string.isRequired,
  }).isRequired,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  })).isRequired,
  onSignout: PropTypes.func.isRequired,
  isAssumedMode: PropTypes.bool,
  userListPanel: PropTypes.node,
  mobileNavOpen: PropTypes.bool,
  handleMobileNavToggle: PropTypes.func,
};

export default BasePageTemplate;
