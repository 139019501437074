import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    marginTop: 16,
  },
  label: {
    color: '#162C5F',
    fontWeight: 500,
    marginTop: 8,
    marginBottom: 8,
  },
  control: {
    '&.Mui-checked': {
      color: '#162C5F',
    },
  },
});

export default useStyles;
