import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  accountIcon: {
    height: 40,
    width: 40,
  },
  button: {
    height: 40,
    '&.MuiButton-root': {
      color: theme.palette.custom.secondary3,
    },
  },
  menu: {
    marginTop: 16,
  },
  menuAccountIcon: {
    height: 24,
    width: 24,
    marginRight: 16,
  },
  menuName: {
    padding: '6px 16px',
  },
  email: {
    color: theme.palette.custom.secondary2,
  },
  username: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
  },
  text: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
  },
  logout: {
    color: theme.palette.custom.primary1,
    fontWeight: 600,
    fontSize: 16,
  },
  navDrawer: {
    '.MuiPaper-root': {
      position: 'relative',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      width: 'auto',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        backgroundImage: 'url("/navbar.svg")',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        content: "''",
        width: 266,
      },
    },
  },
  toolBar: {
    display: 'flex',
  },
  closeButton: {
    marginLeft: 'auto',
  },
}));

export default useStyles;
