import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: '16px auto auto',
    [theme.breakpoints.up('sm')]: {
      margin: '120px auto auto',
    },
  },
  paper: {
    padding: '24px',
  },
}));

export default useStyles;
