import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from './404.svg';

const NotFound = ({ isAuthenticated }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '100px',
  }}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flext-start',
      marginLeft: '8px',
      marginRight: '8px',
      textAlign: 'center',
      color: '#2C416F',
    }}
    >
      <img src={Image} alt="404" style={{ maxWidth: '100%' }} />
      <h1 style={{ marginBottom: 0, fontSize: '24px' }}>We apologise, there&apos;s a problem with your request.</h1>
      <p style={{ color: '#979797', fontFamily: 'Nunito' }}>Please close and open Path software and try again.</p>
      {!isAuthenticated && (
        <Link to="/">Sign in or create an account</Link>
      )}
    </div>
  </div>
);

NotFound.propTypes = {
  isAuthenticated: PropTypes.bool,
};

NotFound.defaultProps = {
  isAuthenticated: false,
};

export default NotFound;
