import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const FormTimePicker = ({
  control, name, rules, inputProps, required, readOnly,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
          renderInput={
            (params) => <TextField required={required} {...params} error={Boolean(error)} />
            }
          ampm
          defaultValue={value}
          format="hh:mm A"
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          {...inputProps}
        />
      </LocalizationProvider>
    )}
  />
);

FormTimePicker.defaultProps = {
  rules: {},
  inputProps: {},
  required: false,
  readOnly: false,
};

FormTimePicker.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormTimePicker;
