import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Icon } from '@mui/material';
import List from '@mui/material/List';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStyles from './styles';

export const ListItem = ({ item, currentPath, onClick }) => {
  const {
    href, text, icon, alt,
  } = item;
  const isSelected = currentPath === href;
  const { classes } = useStyles({ isSelected });
  const navigate = useNavigate();

  const handleOnCLick = () => {
    if (onClick) {
      onClick();
    }
    navigate(href);
  };

  return (
    <ListItemButton
      className={classes.button}
      selected={isSelected}
      onClick={handleOnCLick}
    >
      <Icon className={classes.iconContainer}>
        <img className={classes.icon} src={icon} alt={alt} />
      </Icon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

ListItem.defaultProps = {
  onClick: null,
};

ListItem.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.node,
    alt: PropTypes.string,
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const NavListItems = ({ navLinkItems, onClick }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <List component="nav" className={classes.items}>
      {navLinkItems.map(
        (item) => (
          <ListItem
            item={item}
            key={item?.text}
            currentPath={currentPath}
            onClick={onClick}
          />
        ),
      )}
    </List>
  );
};

NavListItems.defaultProps = {
  onClick: null,
};

NavListItems.propTypes = {
  navLinkItems: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.node,
  })).isRequired,
  onClick: PropTypes.func,
};

export default NavListItems;
