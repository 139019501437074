import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  formatForInvoiceDisplay,
  formatAmount,
} from '../../../../helpers';
import useStyles from './styles';

const InvoiceLineItems = ({ invoice }) => {
  const { lines, currency } = invoice;
  const { classes } = useStyles();

  return (
    <Grid container style={{ paddingTop: 16 }}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <strong>Invoice Line Items</strong>
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TableContainer style={{ overflow: 'auto', maxHeight: 300 }}>
          <Table size="small" stickyHeader aria-label="invoice line items">
            <TableHead>
              <TableRow>
                <TableCell><strong>Id</strong></TableCell>
                <TableCell><strong>Description</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell align="right"><strong>Duration (hours)</strong></TableCell>
                <TableCell align="right"><strong>Amount</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines.map((line) => (
                <TableRow key={line.id}>
                  <TableCell component="th" scope="row" className={classes.id}>
                    {line.id}
                  </TableCell>
                  <TableCell>{line.description}</TableCell>
                  <TableCell>{formatForInvoiceDisplay(
                    line.metadata.session.date,
                    line.metadata.session.totalHoursTutored,
                  )}
                  </TableCell>
                  <TableCell align="right">{line.metadata.session.totalHoursTutored}</TableCell>
                  <TableCell align="right">{formatAmount(line.amount, line.currency)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="invoice line items total">
            <TableBody>
              <TableRow>
                <TableCell align="right" style={{ border: 0, paddingRight: '30px' }}>
                  <strong>Total</strong>&nbsp;
                  {formatAmount(lines.reduce((total, item) => total + item.amount, 0), currency)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

InvoiceLineItems.propTypes = {
  invoice: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    })).isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoiceLineItems;
