const Error = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '100px',
  }}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      border: '2px solid #2C416F',
      borderRadius: '5px',
    }}
    >
      <div style={{
        backgroundColor: '#2C416F',
        padding: '8px',
      }}
      >
        <div>
          <img src="/patheducation-logo.svg" alt="path education logo" />
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flext-start',
        marginLeft: '8px',
        marginRight: '8px',
        color: '#2C416F',
      }}
      >
        <h1>Application error</h1>
        <p>
          <strong>
            Sorry! There appears to be a problem with the Path Education platform.
          </strong>
        </p>
        <p>
          Try navigating back to try again. If the problem persists please try again later.
        </p>
      </div>
    </div>
  </div>
);

export default Error;
