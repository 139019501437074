import { Children } from 'react';
import {
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingBottom: '4px',
  },
  spacer: {
    width: theme.spacing(1),
    maxWidth: theme.spacing(1),
    minWidth: theme.spacing(1),
  },
}));

const DataGridToolbarContainer = ({ children, ...props }) => {
  const { classes } = useStyles();

  const childrenArray = Children.toArray(children);

  return (
    <>
      <GridToolbarContainer className={classes.root} {...props}>
        {Children.map(childrenArray, (child, i) => {
          if (i >= 0 && i !== childrenArray.length - 1) {
            return <>{child} <div className={classes.spacer} /></>;
          }
          return child;
        })}
      </GridToolbarContainer>
      <Divider variant="fullWidth" />
    </>
  );
};

export default DataGridToolbarContainer;
