import { API } from '@aws-amplify/api';
import Result from './result';

export const del = async (api, path, opts = {}) => {
  const result = new Result();

  try {
    result.ok = await API.del(api, path, opts);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const get = async (api, path, opts = {}) => {
  const result = new Result();

  try {
    result.ok = await API.get(api, path, opts);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const patch = async (api, path, opts = {}) => {
  const result = new Result();

  try {
    result.ok = await API.patch(api, path, opts);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const post = async (api, path, opts = {}) => {
  const result = new Result();

  try {
    result.ok = await API.post(api, path, opts);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export const put = async (api, path, opts = {}) => {
  const result = new Result();

  try {
    result.ok = await API.put(api, path, opts);
  } catch (error) {
    result.error = error;
  }

  return result;
};

export default {
  del,
  get,
  patch,
  post,
  put,
};
