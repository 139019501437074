import { makeStyles } from 'tss-react/mui';
import { theme } from '../../../utils';

const useStyles = makeStyles()({
  spacer: {
    paddingBottom: 8,
  },
  sectionMargin: {
    marginTop: 24,
  },
  addButton: {
    paddingTop: 8,
  },
  deleteIcon: {
    marginTop: 32,
  },
  label: {
    paddingTop: 20,
    color: '#162C5F',
    fontWeight: 500,
    fontSize: 16,
  },
});

export default useStyles;
