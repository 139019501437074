import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  date: {
    marginTop: 16,
    marginBottom: 8,
  },
  prefix: {
    marginTop: 15,
  },
});

export default useStyles;
