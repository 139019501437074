import { useCallback, useEffect } from 'react';
import useUserStore, { userSelector } from '../../store/userStore';

const useUser = () => {
  const { user, getUser } = useUserStore(userSelector);

  const handleGetUser = useCallback(
    async () => getUser(),
    [getUser],
  );

  useEffect(() => {
    handleGetUser();
  }, [handleGetUser]);

  return {
    userData: user,
  };
};

export default useUser;
