import { api, basePath } from '../core/config';
import { post, get } from '../core/api';

export const assignLearners = async ({ id, learners }, opts = {}) => post(
  api,
  `${basePath.admin}/users/${id}/learners`,
  {
    ...opts,
    body: {
      learners,
      ...(opts?.body ?? {}),
    },
  },
);

export const getUserProfile = async ({ id }, opts = {}) => get(
  api,
  `${basePath.admin}/users/${id}`,
  opts,
);

export const getTutorBilling = async ({ id }, opts = {}) => get(
  api,
  `${basePath.admin}/users/${id}/billing`,
  opts,
);

export const getUsers = async ({ attributes, userTypes }, opts = {}) => post(
  api,
  `${basePath.admin}/users`,
  {
    ...opts,
    body: {
      ...(attributes && { attributes }),
      ...(userTypes && { userTypes }),
      ...(opts?.body ?? {}),
    },
  },
);

export const verifyUser = async ({ users }, opts = {}) => post(
  api,
  `${basePath.admin}/users/verify`,
  {
    ...opts,
    body: {
      users,
      ...opts.body ?? {},
    },
  },
);

export const getAllTutorInvoices = async (opts = {}) => get(
  api,
  `${basePath.admin}/users/invoices`,
  opts,
);

export const udpateTutorInvoiceStatus = async ({ invoiceId, tutorId, status }, opts = {}) => post(
  api,
  `${basePath.admin}/users/invoices/status`,
  {
    ...opts,
    body: {
      tutorId,
      invoiceId,
      status,
      ...opts.body ?? {},
    },
  },
);

export const resetUserPassword = async ({ id, password }, opts = {}) => post(
  api,
  `${basePath.admin}/users/reset-password`,
  {
    ...opts,
    body: {
      id,
      password,
      ...opts.body ?? {},
    },
  },
);

export default {
  udpateTutorInvoiceStatus,
  getAllTutorInvoices,
  assignLearners,
  getUserProfile,
  getUsers,
  verifyUser,
  resetUserPassword,
};
