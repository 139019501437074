import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';
import useStyles from './styles';

const Button = ({ variant, children, ...otherProps }) => {
  const { classes } = useStyles();
  return (
    <MuiButton
      variant={variant === 'link' ? 'text' : variant}
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
        containedSecondary: classes.containedSecondary,
        outlinedPrimary: classes.outlinedPrimary,
        textPrimary: variant === 'link' ? classes.linkPrimary : undefined,
      }}
      {...otherProps}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
};

Button.propTypes = {
  variant: PropTypes.string,
};

export default Button;
