import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  containedPrimary: {
    backgroundColor: '#162C5F',
    '&:hover': {
      backgroundColor: '#2C416F',
    },
  },
  containedSecondary: {
    color: '#162C5F',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },
  outlinedPrimary: {
    color: '#162C5F',
    borderColor: '#162C5F',
    '&:hover': {
      borderColor: '#162C5F',
    },
  },
  linkPrimary: {
    color: '#162C5F',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px',
    border: 0,
    padding: 0,
    margin: 0,
    minWidth: '0px',
    fontSize: '16px',
    minHeight: '0px',
    paddingBottom: '3px',

    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

export default useStyles;
