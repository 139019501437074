import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  alert: {
    marginBottom: 10,
  },
  heading: {
    marginBottom: '30px',
  },
  paper: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  unverifiedRow: {
    background: 'rgb(253, 237, 237)',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: 40,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default useStyles;
