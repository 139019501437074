import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  paper: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  list: {
    listStylePosition: 'outside',
    paddingLeft: 16,
    '>li': {
      margin: '10px 0',
    },
  },
  listUnstyled: {
    paddingLeft: 0,
    listStyleType: 'none',
    '>li': {
      margin: '10px 0',
    },
  },
  unorderedList: {
    listStyleType: 'none',
    '>li': {
      margin: '10px 0',
    },
  },
  header: {
    fontSize: 24,
  },
}));
