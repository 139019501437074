import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import useStyles from './styles';
import NotificationListItem from '../NotificationListItem';

const NotificationList = ({
  user,
  notifications,
  onNotificationDelete,
  avatarUriPrefix,
}) => {
  const { classes } = useStyles();

  if (!notifications?.items?.length) {
    return (
      <Typography variant="body1" className={classes.noNotificationsMessage}>There are no notifications at this time</Typography>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className={classes.notifications}>
      {notifications.error && <Alert severity="error" className={classes.alert}>{notifications.error}</Alert>}
      <List>
        {notifications.items.map((notification) => (
          <NotificationListItem
            key={notification.notificationId}
            notification={notification}
            user={user}
            onDelete={onNotificationDelete}
            avatarUriPrefix={avatarUriPrefix}
          />
        ))}
      </List>
    </div>
  );
};
NotificationList.defaultProps = {
  avatarUriPrefix: null,
};

NotificationList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
  notifications: PropTypes.shape({
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      notificationId: PropTypes.string.isRequired,
    })),
  }).isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  avatarUriPrefix: PropTypes.string,
};
export default NotificationList;
