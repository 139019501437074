import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    margin: 24,
  },
  date: {
    marginTop: 8,
    '> div': {
      width: '100%',
    },
  },
  autocomplete: {
    marginTop: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'end',
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
    color: theme.palette.custom.primary2,
    fontWeight: 700,
    fontSize: 20,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 16,
  },
  actionButton: {
    '&.MuiButton-root': {
      marginLeft: 16,
    },
  },
  modal: {
    overflow: 'scroll',
  },
  modalContainer: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    maxWidth: 700,
    margin: '50px auto',
    borderRadius: 16,
    maxHeight: '90vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: 'none',
      height: '100%',
    },
  },
  modalActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    gap: 16,
  },
}));

export default useStyles;
