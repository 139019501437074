import PropTypes from 'prop-types';
import { BasePageTemplate } from '~common/components';
import useStyles from './styles';

export const navigationItems = [
  {
    href: '/', text: 'Dashboard', icon: '/calendar.svg', alt: 'dashboard-icon',
  },
  {
    href: '/users', text: 'Users', icon: '/people-filled.svg', alt: 'users-icon',
  },
  {
    href: '/tutor-invoices', text: 'Tutor Invoices', icon: '/tutor-invoices.svg', alt: 'tutor-invoices-icon',
  },
  {
    href: '/lessons', text: 'All Lessons', icon: '/menu-board.svg', alt: 'lessons-icon',
  },
  {
    href: '/notifications', text: 'Notifications', icon: '/notification-bing.svg', alt: 'notifications-icon',
  },
];

const PageTemplate = ({
  children, handleSignOut, user, userData,
}) => {
  const {
    isAuthenticated, isVerified,
  } = user;
  const { classes } = useStyles();

  if (!isAuthenticated || !isVerified) {
    return (
      children
    );
  }

  return (
    <BasePageTemplate
      userData={userData}
      navigationItems={navigationItems}
      classes={classes}
      onSignout={handleSignOut}
    >
      {children}
    </BasePageTemplate>
  );
};

PageTemplate.defaultProps = {
  userData: {
    fullName: '',
  },
};

PageTemplate.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isVerified: PropTypes.bool,
    userType: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userData: PropTypes.shape({
    fullName: PropTypes.string,
  }),
};

export default PageTemplate;
