import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert, CircularProgress } from '@mui/material';
import Button from '../../button';
import useStyles from './styles';

export const FileUploadStates = {
  loading: 'LOADING',
  error: 'ERROR',
  success: 'SUCCESS',
  maxSizeLimit: 'MAXSIZELIMIT',
};

const FileUploadInput = ({ label, handleFileUpload, status }) => {
  const { classes } = useStyles();

  return (
    <>
      {label && (
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      )}
      {status === FileUploadStates.success && (
      <Alert severity="success" className={classes.statusMessage}>
        File uploaded successfully.
      </Alert>
      )}
      {status === FileUploadStates.error && (
      <Alert severity="error" className={classes.statusMessage}>
        Error uploading file.
      </Alert>
      )}
      {status === FileUploadStates.maxSizeLimit && (
      <Alert severity="error" className={classes.statusMessage}>
        File size should not exceed 10mb. Please upload a smaller file.
      </Alert>
      )}
      <Box component="div" className={classes.box}>
        {status === FileUploadStates.loading ? (<CircularProgress />)
          : (
            <>
              <Button variant="outlined" component="label">
                BROWSE
                <input onChange={handleFileUpload} hidden type="file" accept=".doc, .docx, .pdf" />
              </Button>
              <Typography variant="body1" className={classes.text}>or drag a file here</Typography>
              <Typography variant="body1" className={classes.text}>Must be .doc, .docx, .pdf. - Max size 10Mb</Typography>
            </>
          )}
      </Box>
    </>
  );
};

FileUploadInput.defaultProps = {
  label: '',
  status: null,
};

FileUploadInput.propTypes = {
  label: PropTypes.string,
  handleFileUpload: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(FileUploadStates)),
};

export default FileUploadInput;
