import { api, basePath } from '../core/config';
import { get } from '../core/api';

export const getTutorInvoices = async (opts = {}) => get(
  api,
  basePath.getTutorInvoices,
  opts,
);

export default {
  getTutorInvoices,
};
