import PropTypes from 'prop-types';
import { Alert, Box, Link } from '@mui/material';

import Button from '../../button';
import useStyles from './styles';

const LoginForm = ({
  children, handleSubmit, handleFormChange, isDisabled, error, formTitle,
}) => {
  const { classes } = useStyles();
  const forgottonPasswordUrl = '/forgotten-password';
  const createAccountUrl = '/create-account';

  return (
    <Box className={classes.box}>
      {formTitle && formTitle}
      <Box
        component="form"
        onSubmit={handleSubmit}
        onChange={handleFormChange}
        noValidate
        className={classes.form}
      >
        {children}
        <Link
          href={forgottonPasswordUrl}
          underline="none"
          className={classes.forgotPasswordLink}
        >
          Forgotten your password?
        </Link>
        {error && (
        <Alert
          severity="error"
          className={classes.alert}
        >
          <span>{error}</span>
        </Alert>
        )}
        <div className={classes.actions}>
          <Link href={createAccountUrl} underline="none" className={classes.link}>Create an account</Link>
          <Button
            type="submit"
            disabled={isDisabled}
          >
            Login
          </Button>
        </div>
      </Box>
    </Box>
  );
};

LoginForm.defaultProps = {
  isDisabled: false,
  error: '',
  formTitle: null,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  formTitle: PropTypes.element,
};

export default LoginForm;
