import { api, basePath } from '../core/config';
import { get, del, post } from '../core/api';

export const getNotifications = async (opts = {}) => get(
  api,
  basePath.notifications,
  opts,
);

export const deleteNotifications = async (notificationId, opts = {}) => del(
  api,
  `${basePath.notifications}/${notificationId}`,
  opts,
);

export const postNotificationsSeen = async (notificationIds, opts = {}) => post(
  api,
  `${basePath.notifications}/seen`,
  {
    ...opts,
    body: {
      ...opts.body,
      notificationIds,
    },
  },
);

export default {
  getNotifications,
  deleteNotifications,
  postNotificationsSeen,
};
