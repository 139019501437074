import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

const FormTitle = ({
  title, description, image, align,
}) => {
  const { classes } = useStyles({ align });

  return (
    <div className={classes.formTitle}>
      {image && (
      <div className={classes.image}>
        <img
          src={image}
          alt="logo"
        />
      </div>
      )}
      <Typography component="h1" variant="h5" className={classes.title}>
        {title}
      </Typography>
      { description && (
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      )}
    </div>
  );
};

FormTitle.defaultProps = {
  description: null,
  image: null,
  align: null,
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  align: PropTypes.string,
};

export default FormTitle;
