const columns = [
  {
    field: 'fullName',
    headerName: 'Learner',
    width: 100,
    flex: 1,
    valueGetter: (params) => {
      const { firstName, lastName } = params.row;
      return `${firstName} ${lastName}`;
    },
  },
  {
    field: 'subjectList',
    headerName: 'Subjects',
    flex: 1,
    valueGetter: (params) => {
      const { subjectList } = params.row;
      const value = subjectList
        .map(({ subject, qualificationLevel }) => `${subject.subjectName} (${qualificationLevel.qualificationLevel})`)
        .join(', ');

      return value;
    },
  },
  {
    field: 'chargeToCustomerRate',
    headerName: 'Customer Charge',
    editable: true,
    type: 'number',
    flex: 1,
    valueGetter: (params) => {
      const { chargeToCustomerRate } = params.row;
      if (Number.isNaN(chargeToCustomerRate)) {
        return 'Double Click to set';
      }

      return chargeToCustomerRate > 0
        ? chargeToCustomerRate / 100
        : chargeToCustomerRate;
    },
    valueSetter: (params) => {
      const { value } = params;
      return { ...params.row, chargeToCustomerRate: Number(value) * 100 };
    },
    preProcessEditCellProps: (params) => {
      const hasError = Number.isNaN(params.props.value)
        || Number(params.props.value) <= 0;
      return { ...params.props, error: hasError };
    },
  },
  {
    field: 'costToPathRate',
    headerName: 'Tutor Cost',
    editable: true,
    type: 'number',
    flex: 1,
    valueGetter: (params) => {
      const { costToPathRate } = params.row;
      if (Number.isNaN(costToPathRate)) {
        return 'Double Click to set';
      }

      return costToPathRate > 0
        ? costToPathRate / 100
        : costToPathRate;
    },
    valueSetter: (params) => {
      const { value } = params;
      return { ...params.row, costToPathRate: Number(value) * 100 };
    },
    preProcessEditCellProps: (params) => {
      const hasError = Number.isNaN(params.props.value)
        || Number(params.props.value) <= 0;
      return { ...params.props, error: hasError };
    },
  },
];

export default columns;
