import Typography from '@mui/material/Typography';

const tutorSignupTemplate = ({ classes, notificationData, userType }) => {
  const { firstName, lastName } = notificationData;

  const message = userType === 'admin'
    ? (
      <>
        {firstName} {lastName} has <strong>signed up</strong> as a{' '}
        prospective <strong>tutor</strong>.
      </>
    )
    : (
      <>
        Welcome to Path Education, <strong>{firstName} {lastName}</strong>.<br />

        If not already done so please remember to upload your CV and DBS documents to expidite your
        application.
      </>
    );

  return (
    <>
      <Typography variant="body1" className={classes.author} component="div">
        <strong>Path Education</strong>
      </Typography>
      <Typography variant="body1" className={classes.message} component="div">
        {message}
      </Typography>
    </>
  );
};

export default tutorSignupTemplate;
