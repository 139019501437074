export default [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 100,
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 100,
    flex: 1,
  },
  {
    field: 'userType',
    headerName: 'Type',
    flex: 1,
    valueFormatter: ({ value }) => (value && value[0].toUpperCase() + value.slice(1)) || '',
  },
  {
    field: 'emailAddress',
    headerName: 'Email Address',
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 1,
    valueGetter: (params) => {
      const { phoneNumberPrefix, phoneNumber } = params.row;
      const { value, id } = phoneNumberPrefix ?? {};

      return value && id && phoneNumber
        ? `(${id}) ${value} ${phoneNumber}`
        : '';
    },
  },
];
