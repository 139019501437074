import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: '#FFFFFF',
    height: 40,
    width: 156,
    marginTop: 25,
  },
  box: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
  appToolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
  navDrawer: {
    '.MuiPaper-root': {
      position: 'relative',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.custom.primary2,
      width: 'auto',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        backgroundImage: 'url("/navbar.svg")',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        content: "''",
        width: 266,
      },
    },
  },
  container: {
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    marginTop: 64,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  closeIcon: {
    fill: '#FFFFFF',
  },
  menuIcon: {
    marginLeft: 4,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
