import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const FormDatePicker = ({
  control, name, rules, inputProps, required, readOnly,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          renderInput={
            // eslint-disable-next-line max-len
            (params) => <TextField required={required} {...params} helperText={error?.message} error={Boolean(error)} />
          }
          readOnly={readOnly}
          format="DD/MM/YYYY"
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          {...inputProps}
        />
      </LocalizationProvider>
    )}
  />
);

FormDatePicker.defaultProps = {
  rules: {},
  inputProps: {},
  required: false,
  readOnly: false,
};

FormDatePicker.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormDatePicker;
