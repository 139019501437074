import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    backgroundColor: theme.palette.custom.primary1,
    border: `1px solid ${theme.palette.custom.primary1}`,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.custom.primary2,
      border: `1px solid ${theme.palette.custom.primary2}`,
    },
    '&:disabled': {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.custom.secondary3}`,
    },
  },
}));

const DataGridToolbarButton = ({ children, ...props }) => {
  const { classes } = useStyles();

  return (
    <Button className={classes.root} {...props}>{children}</Button>
  );
};

export default DataGridToolbarButton;
