import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  paper: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  subTitle: {
    marginBottom: 0,
    fontWeight: 'bold',
  },
  text: {
    marginTop: 5,
  },
  header: {
    fontSize: 24,
  },
}));
