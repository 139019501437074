import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AssignLearnerIcon from '@mui/icons-material/School';
import AssumeUserIcon from '@mui/icons-material/SwitchAccount';
import LockResetIcon from '@mui/icons-material/LockReset';
import PageTitle from '../../components/pageTitle';
import DataGrid from '../../components/dataGrid';
import DataGridToolbarContainer from '../../components/dataGrid/toolbar/toolbarContainer';
import DataGridToolbarQuickFilter from '../../components/dataGrid/toolbar/toolbarQuickFilter';
import DataGridToolbarSpacer from '../../components/dataGrid/toolbar/toolbarSpacer';
import DataGridToolbarRefreshButton from '../../components/dataGrid/toolbar/toolbarButtons/toolbarRefreshButton';
import DataGridToolbarButton from '../../components/dataGrid/toolbar/toolbarButton';

import AssignLearnersDialog from './assignLearnersDialog';
import useUsers from './hooks/useUsers';
import useStyles from './styles';
import columns from './columns';
import ResetPasswordDialog from './resetPasswordDialog';

const getToolbar = ({
  selectedUser,
  onAssumeUserClick,
  onRefreshClick,
  openAssignLearnersDialog,
  openResetPasswordDialog,
}) => function () {
  return (
    <DataGridToolbarContainer>
      <DataGridToolbarRefreshButton onClick={onRefreshClick} />
      <DataGridToolbarQuickFilter />
      <DataGridToolbarSpacer />
      <DataGridToolbarButton
        startIcon={<AssignLearnerIcon />}
        disabled={!(selectedUser && selectedUser.userType === 'tutor')}
        onClick={openAssignLearnersDialog}
      >
        Manage learners
      </DataGridToolbarButton>
      {/* <DataGridToolbarButton
      startIcon={<DeleteUserIcon />}
      disabled={!(selectedUser && selectedUser.userType !== 'admin')}
    >
      Delete user
    </DataGridToolbarButton> */}
      <DataGridToolbarButton
        startIcon={<AssumeUserIcon />}
        disabled={!(selectedUser && selectedUser.userType !== 'admin')}
        onClick={onAssumeUserClick}
      >
        Assume user
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<LockResetIcon />}
        disabled={!(selectedUser && selectedUser.userType !== 'admin')}
        onClick={openResetPasswordDialog}
      >
        Reset Password
      </DataGridToolbarButton>
    </DataGridToolbarContainer>
  );
};

function Users() {
  const { classes } = useStyles();
  const {
    data,
    loading,
    alert,
    selectedUser,
    selectionModel,
    onSelectionModelChange,
    onAssumeUserClick,
    onRefreshClick,
    showAssignLearnersDailog,
    openAssignLearnersDialog,
    dismissAssignLearnersDialog,
    submitAssignLearnersDialog,
    showPasswordResetDialog,
    openResetPasswordDialog,
    dismissPasswordResetDialog,
    submitPasswordResetDialog,
  } = useUsers();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title="Users" />
      </div>
      <div className={classes.content}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.background}>
              {alert && (
                <Alert severity={alert.severity} className={classes.alert}>{alert.message}</Alert>
              )}
              <DataGrid
                className={classes.dataGrid}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'firstName', sort: 'asc' }],
                  },
                }}
                loading={loading}
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                components={{
                  Toolbar: getToolbar({
                    selectedUser,
                    onAssumeUserClick,
                    onRefreshClick,
                    openAssignLearnersDialog,
                    openResetPasswordDialog,
                  }),
                }}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                pageSize={20}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {showAssignLearnersDailog && (
        <AssignLearnersDialog
          id={selectedUser?.id}
          open={showAssignLearnersDailog}
          onClose={dismissAssignLearnersDialog}
          onCancel={dismissAssignLearnersDialog}
          onSubmit={submitAssignLearnersDialog}
        />
      )}
      {showPasswordResetDialog && (
      <ResetPasswordDialog
        id={selectedUser?.id}
        open={showPasswordResetDialog}
        onClose={dismissPasswordResetDialog}
        onCancel={dismissPasswordResetDialog}
        onSubmit={submitPasswordResetDialog}
      />
      )}
    </div>
  );
}

export default Users;
