import { create } from 'zustand';
import { getUser, updateUser, createLearner } from '~common/api';
import { userColors } from '../utils/colors';
import config from '../utils/config';

const { UserType } = config;

export const getUserColorMap = (user) => {
  const userColorsLength = userColors.length;
  const userList = user.userType === UserType.TUTOR ? user.learners : user.tutors;

  // Assign each user id a colour from the userColors list. If there are more users than colours
  // in the list than assign from the beginning of the list again.
  return userList
    ? Object.fromEntries(
      userList.map((u, i) => ([u?.id, userColors[i % userColorsLength]])),
    )
    : null;
};

const useUser = create((set) => ({
  assumedUserId: null,
  user: {},
  isAssumedMode: false,
  setAssumedUserId: async (id) => {
    set((state) => ({
      ...state,
      assumedUserId: id,
      isAssumedMode: !!id,
    }));
  },
  getUser: async () => {
    const result = await getUser();

    if (result.isOk) {
      set((state) => ({
        ...state,
        user: {
          ...result.ok,
          userColorMap: getUserColorMap(result.ok),
        },
      }));
    }
    return result;
  },
  updateUser: async (body) => {
    const result = await updateUser(body);

    if (result.isOk) {
      set((state) => ({
        ...state,
        user: result.ok,
      }));
    }
    return result;
  },
  createLearner: async (body) => {
    const result = await createLearner(body);

    if (result.isOk) {
      set((state) => ({
        ...state,
        user: {
          ...state.user,
          learners: [...state.user.learners, result.ok],
        },
      }));
    }

    return result;
  },
}));

export default useUser;
