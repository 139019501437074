import { makeStyles } from 'tss-react/mui';

const sizeMap = {
  s: {
    width: 24,
    height: 24,
  },
  m: {
    width: 40,
    height: 40,
  },
  l: {
    width: 120,
    height: 120,
  },
  large: {
    width: 120,
    height: 120,
  },
  xl: {
    width: 140,
    height: 140,
  },
};

const borderMap = () => ({
  s: {
    borderRadius: '50%',
  },
  m: {
    borderRadius: '50%',
  },
  l: {
    borderRadius: '50%',
    boxShadow: '0px 10px 50px #B6B9C7',
  },
  xl: {
    borderRadius: '50%',
    boxShadow: '0px 10px 50px #B6B9C7',
  },
});

export default makeStyles()((theme, { size, onClick }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.custom.secondary3,
    objectFit: 'fit',
    overflow: 'hidden',
    ...(sizeMap[size]),
    ...(borderMap()[size]),

    '& :hover': {
      cursor: onClick && 'pointer',
    },
  },
  overlay: {
    float: 'left',
    position: 'absolute',
    display: 'inline-block',
    color: 'white',
    objectFit: 'fit',
    overflow: 'hidden',
    ...(sizeMap[size]),
    ...(borderMap()[size]),
  },
  noavatar: {
    color: theme.palette.custom.secondary3,
    ...(sizeMap[size]),
  },
  image: {
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.custom.secondary3,
    objectFit: 'fit',
    ...(sizeMap[size]),
  },
}));
