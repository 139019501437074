import { create } from 'zustand';
import { getTutorInvoices } from '~common/api';

const formatInvoices = (data) => {
  const formattedInvoices = data.invoices
    ?.map((i) => ({
      ...i,
      lines: i.lines?.map((l) => ({ ...l, ...l?.metadata?.session })),
    }));

  return { ...data, invoices: formattedInvoices };
};

const useTutorInvoicesStore = create((set) => ({
  invoices: [],
  getTutorInvoices: async () => {
    const result = await getTutorInvoices();

    if (result.isOk) {
      set((state) => ({
        ...state,
        invoices: formatInvoices(result.ok),
      }));
    }
    return (result);
  },
}));

export default useTutorInvoicesStore;
