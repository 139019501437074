import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';

import { Error, NotFound } from '~common/components';
import { useAuth } from '~common/utils';
import PageTemplate from '../components/PageTemplate';
import PrivateRoute from './PrivateRoute';
import useUser from './hooks/useUser';
import Dashboard from '../pages/dashboard';
import Users from '../pages/users';
import TutorInvoices from '../pages/tutorInvoices';
import Lessons from '../pages/lessons';
import Notifications from '../pages/notifications';

function App() {
  const {
    loading, handleSignOut, user,
  } = useAuth({ Hub, Auth, bypassCache: false });
  const { userData } = useUser();

  const routes = (
    <Router>
      {!loading && (
        <PageTemplate
          handleSignOut={handleSignOut}
          user={user}
          userData={userData}
        >
          <Routes>
            <>
              <Route
                index
                path="/"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} loading={loading}>
                    <Dashboard />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/users"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} loading={loading}>
                    <Users />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/tutor-invoices"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} loading={loading}>
                    <TutorInvoices />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/lessons"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} loading={loading}>
                    <Lessons />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/notifications"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} loading={loading}>
                    <Notifications userData={user} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="*"
                element={<NotFound isAuthenticated={user?.isAuthenticated} />}
              />
            </>
          </Routes>
        </PageTemplate>
      )}
    </Router>
  );

  const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
    && import.meta.env.VITE_SENTRY_ENV !== 'staging';

  return isDev
    ? routes
    : (
      <Sentry.ErrorBoundary fallback={<Error />}>
        {routes}
      </Sentry.ErrorBoundary>
    );
}

export default App;
