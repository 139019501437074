import PropTypes from 'prop-types';
import {
  Container,
  Paper,
} from '@mui/material';
import useStyles from './styles';

const FormContainer = ({ children, maxWidth }) => {
  const { classes } = useStyles();

  return (
    <Container component="main" maxWidth={maxWidth} className={classes.container}>
      <Paper className={classes.paper}>
        { children }
      </Paper>
    </Container>
  );
};

FormContainer.defaultProps = {
  maxWidth: 'xs',
};

FormContainer.propTypes = {
  maxWidth: PropTypes.string,
};

export default FormContainer;
