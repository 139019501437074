import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  formTitle: {
    textAlign: props?.align && props?.align,
    marginBottom: !props?.align && 24,
  },
  title: {
    paddingBottom: 4,
    color: '#162C5F',
    fontWeight: 600,
  },
  description: {
    color: '#969696',
  },
}));

export default useStyles;
