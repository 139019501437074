import { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  getSessions,
  getUsers,
  getUserProfile,
  getTutorBilling,
} from '~common/api';
import { useSessionsStore } from '~common/store';

export const defaultAttributes = [
  'id',
  'firstName',
  'lastName',
  'emailAddress',
  'phoneNumberPrefix',
  'phoneNumber',
  'userType',
];

const alerts = {
  undo: {
    severity: 'info',
    message: 'Learner assignments reset',
  },
  errorTutorProfile: {
    severity: 'error',
    message: 'There was an error attempting to retrieve tutor details, please try again later',
  },
  errorTutorBilling: {
    severity: 'error',
    message: 'There was an error attempting to retrieve tutor billing details, please try again later',
  },
  errorGetLearners: {
    severity: 'error',
    message: 'There was an error attempting to retrieve learners, please try again later',
  },
  warnSetRates: {
    severity: 'warning',
    message: 'Remember to set Customer Charge and Tutor Cost for learner',
  },
  errorInvalidLearners: {
    severity: 'error',
    message: 'One or mode learners are missing Customer Charge and/or Tutor Cost values',
  },
};

export const sessionsSelector = (state) => ({
  updateSession: state.updateSession,
  cancelSession: state.cancelSession,
});

export default ({
  startDate = 0,
  endDate = 4102444800000,
} = {}) => {
  const [, setError] = useState(null);
  const [, setSuccess] = useState(null);
  const [cancelledAppointmentId, setcancelledAppointmentId] = useState(undefined);
  const [, setConfirmationVisible] = useState(false);
  const [state, setState] = useState({
    data: [],
    loading: null,
    alert: null,
    selectionModel: [],
    selectedSession: null,
    showEditSessionDialog: false,
    showEditCostDialog: false,
    showExportDialog: false,
  });

  const {
    updateSession,
    cancelSession,
  } = useSessionsStore(sessionsSelector);

  const handleGetAllSessions = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const result = await getSessions(startDate, endDate);
    setState((prevState) => ({
      ...prevState,
      loading: false,
      data: result?.ok ?? [],
      alert: result.isError
        ? {
          severity: 'error',
          message: 'There was an error fetching sessions please try again later',
        }
        : null,
    }));
  };

  const onSelectionModelChange = useCallback((selectionModel) => {
    const sessionId = selectionModel[0];
    const selectedSession = state.data
      ? state.data?.find((session) => session.id === sessionId)
      : null;

    setState((prevState) => ({
      ...prevState,
      selectionModel,
      selectedSession,
      alert: null,
    }));
  }, [state.data]);

  const onRefreshClick = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      alert: null,
    }));
    handleGetAllSessions();
  });

  const openEditSessionDialog = useCallback(() => {
    if (state.selectedSession) {
      setState((prevState) => ({
        ...prevState,
        showEditSessionDialog: true,
      }));
    }
  }, [state.selectedSession]);

  const dismissEditSessionDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showEditSessionDialog: false,
    }));
  });

  const openExportDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showExportDialog: true,
    }));
  });

  const dismissExportDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showExportDialog: false,
    }));
  });

  const submitEditSessionDialog = useCallback(async () => {
  });

  const openEditCostDialog = useCallback(() => {
    if (state.selectedSession) {
      setState((prevState) => ({
        ...prevState,
        showEditCostDialog: true,
      }));
    }
  }, [state.selectedSession]);

  const dismissEditCostDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showEditCostDialog: false,
    }));
  });

  const submitEditCostDialog = useCallback(async () => {
  });

  const fetchData = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const [tutor, getProfileErr] = await getUserProfile({
      id: state.selectedSession.tutors[0].id,
    });
    if (getProfileErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorTutorProfile,
        loading: false,
      }));

      return;
    }

    const [tutorBilling, getTutorBillingErr] = await getTutorBilling({
      id: state.selectedSession.tutors[0].id,
    });
    if (getTutorBillingErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorTutorBilling,
        loading: false,
      }));

      return;
    }

    const [learners, getUsersErr] = await getUsers({
      attributes: [
        'id',
        'firstName',
        'lastName',
        'emailAddress',
        'ownAccount',
        'contact',
        'parent',
        'subjectList',
      ],
      userTypes: [
        'learner',
      ],
    });
    if (getUsersErr) {
      setState((prevState) => ({
        ...prevState,
        alert: alerts.errorGetLearners,
        loading: false,
      }));

      return;
    }

    const formattedLearners = learners?.results?.reduce((acc, learner) => {
      const formattedLearner = {
        ...learner,
        ...tutorBilling.learners?.[learner.id] ?? {
          chargeToCustomerRate: learner.chargeToCustomerRate ?? 'Double click to set',
          costToPathRate: learner?.costToPathRate ?? 'Double click to set',
        },
      };

      acc.learners[learner.id] = formattedLearner;

      if (tutor.learners?.findIndex((l) => l.id === formattedLearner.id) > -1) {
        acc.selectedLearners[formattedLearner.id] = formattedLearner;
      }

      return acc;
    }, { learners: {}, selectedLearners: {} });

    setState((prevState) => ({
      ...prevState,
      ...formattedLearners,
      tutor,
      defaultLearners: formattedLearners.learners,
      defaultSelectedLearners: formattedLearners.selectedLearners,
      loading: false,
    }));
  };

  const handleEditCost = async (editedSessionData) => {
    const updateData = {
      id: editedSessionData.id,
      values: { ...editedSessionData },
    };
    await updateSession(updateData);

    // Refresh data.
    setState((prevState) => ({
      ...prevState,
      selectedSession: null,
      selectionModel: [],
    }));
    handleGetAllSessions();
  };

  const handleEditSession = async (editedSessionData) => {
    /* eslint-disable no-shadow */

    const {
      id,
      title,
      callDetails,
      meetingDetails,
      description,
      subject,
      lessonDuration,
      startDate,
      freeTrial,
    } = editedSessionData;

    const endDate = lessonDuration.value
      ? dayjs(startDate).valueOf() + lessonDuration.value : null;

    await updateSession({
      id,
      values: {
        title,
        startDate: dayjs(startDate).valueOf(),
        endDate,
        subject,
        description,
        callDetails,
        meetingDetails,
        freeTrial,
      },
    });

    // Refresh data.
    setState((prevState) => ({
      ...prevState,
      selectedSession: null,
      selectionModel: [],
    }));
    handleGetAllSessions();
  };

  const commitCancelledAppointment = async (charge) => {
    setConfirmationVisible(false);
    try {
      await cancelSession(cancelledAppointmentId, charge);
      setSuccess('Session successfully cancelled.');
    } catch (e) {
      setError('There has been an error cancelling this session. Please try again or contact an administrator.');
    }
  };

  const commitChanges = async ({ cancelled, changed }) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    setSuccess(null);
    setError(null);

    if (changed) {
      try {
        await handleEditSession(changed);
        setSuccess('Session edited successfully.');
      } catch (e) {
        setError('There has been an error editing this session. Please try again or contact an administrator.');
      }
    } else if (cancelled) {
      setcancelledAppointmentId(cancelled);
      setConfirmationVisible(true);
    }

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  useEffect(() => {
    if (state.selectedSession) {
      fetchData();
    }
  }, [state.selectedSession]);

  useEffect(() => {
    handleGetAllSessions();
  }, []);

  return {
    ...state,
    onSelectionModelChange,
    onRefreshClick,
    openEditSessionDialog,
    dismissEditSessionDialog,
    submitEditSessionDialog,
    openEditCostDialog,
    dismissEditCostDialog,
    submitEditCostDialog,
    openExportDialog,
    dismissExportDialog,
    commitChanges,
    commitCancelledAppointment,
    handleEditCost,
  };
};
