import { create } from 'zustand';
import dayjs from 'dayjs';
import {
  getSessions, addSession, updateSession, cancelSession,
} from '~common/api';

export const formatSession = (session) => ({
  startDate: session.startDate,
  endDate: session.endDate,
  title: session.title,
  id: session.sessionId || session.id,
  callDetails: session.callDetails,
  meetingDetails: session.meetingDetails,
  subject: session.subject,
  description: session.description,
  status: session.status,
  learner: {
    ...session?.learners[0],
    fullName: `${session?.learners[0]?.firstName} ${session?.learners[0]?.lastName}`,
  },
  tutor: {
    ...session?.tutors[0],
    fullName: `${session?.tutors[0]?.firstName} ${session?.tutors[0]?.lastName}`,
  },
  freeTrial: session.freeTrial,
});

export const formatSessions = (calendarSessions) => calendarSessions.map(
  (session) => formatSession(session),
);

const replaceSession = (sessions, newSession) => {
  const index = sessions.findIndex((x) => x.id === newSession.id);
  sessions[index] = { ...sessions[index], ...newSession };
  return sessions;
};

const CANCELLED_STATUS = ['CANCELLED_WITH_CHARGE', 'CANCELLED_NO_CHARGE'];
const getUpComingSessions = (sessions) => {
  const upcomingSessionsAmount = 3;
  const filteredSessions = sessions.filter((session) => !CANCELLED_STATUS.includes(session.status));
  const firstUpcomingSessionInd = [...filteredSessions]
    .findIndex((s) => s.endDate > dayjs().valueOf());

  return firstUpcomingSessionInd >= 0
    ? [...filteredSessions]
      .splice(firstUpcomingSessionInd, upcomingSessionsAmount)
    : [];
};

const useSessions = create((set) => ({
  sessions: [],
  upcomingSessions: [],
  getSessions: async (startDate, endDate, filterCondition) => {
    const result = await getSessions(startDate, endDate);

    if (result.isOk) {
      let sessions = formatSessions(result.ok);

      if (filterCondition) {
        sessions = sessions.filter(filterCondition);
      }

      // If we're searching forwards i.e endDate is before 00:00 today, sort so we have the
      // latest session first.
      if (dayjs().isBefore(dayjs(endDate), 'minutes')) { // Future Lessons
        sessions.sort((a, b) => a.startDate - b.startDate);
      } else { // Past Lessons
        sessions.sort((a, b) => b.startDate - a.startDate);
      }

      set((state) => ({
        ...state,
        sessions,
        upcomingSessions: getUpComingSessions(sessions),
      }));
    } else if (result.isError) {
      throw new Error(result.error);
    }
  },
  addSession: async (body) => {
    const result = await addSession(body);

    if (result.isOk) {
      set((state) => {
        const newSessions = [
          ...state.sessions, formatSession({ ...result?.ok, sessionId: result?.ok?.id }),
        ]
          .sort((a, b) => a.startDate - b.startDate);

        return {
          ...state,
          sessions: newSessions,
          upcomingSessions: getUpComingSessions(newSessions),
        };
      });
    } else if (result.isError) {
      throw new Error(result.error);
    }
  },
  updateSession: async (body) => {
    const { id, values } = body;
    const result = await updateSession(body);

    if (result.isOk) {
      set((state) => {
        const newSessions = replaceSession(state.sessions, { id, ...values })
          .sort((a, b) => a.startDate - b.startDate);

        return {
          ...state,
          sessions: newSessions,
          upcomingSessions: getUpComingSessions(newSessions),
        };
      });
    } else if (result.isError) {
      throw new Error(result.error);
    }
  },
  cancelSession: async (id, charge) => {
    if (!id) {
      throw new Error('No id passed through to cancelSession');
    }

    if (typeof (charge) !== 'boolean') {
      throw new Error('No charge passed through to cancelSession');
    }

    const body = { id, charge };
    const result = await cancelSession(body);

    if (result.isOk) {
      const newSession = result.ok;

      set((state) => {
        const newSessions = replaceSession(state.sessions, newSession)
          .sort((a, b) => a.startDate - b.startDate);

        return {
          ...state,
          sessions: newSessions,
          upcomingSessions: getUpComingSessions(newSessions),
        };
      });
    } else if (result.isError) {
      throw new Error(result.error);
    }
  },
}));

export default useSessions;
