// IMPORTANT: This file is duplicated in path-education-backend and path-education-frontend.
// Please make sure any changes are replicated in both files.

import dayjs from 'dayjs';
import {
  number, string, object, array, date, bool,
} from 'yup';
import fields, { errorMessages } from './fields';

export const FILE_SIZE = 10485760; // 10Mb limit
export const SUPPORTED_FORMATS = ['.doc', '.docx', '.pdf'];

export const costFormSchema = object({
  chargeToPath: fields.costToPathRateRequired,
  chargeToCustomer: fields.chargeToCustomerRateRequired,
}).required();

export const passwordSchema = object({
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
}).required();

export const changePasswordSchema = object({
  emailAddress: string()
    .when('$exist', {
      is: (exist) => exist,
      then: fields.emailAddressRequired,
      otherwise: string().email(errorMessages.email),
    }),
  currentPassword: fields.passwordRequired,
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
});

export const forgottenPasswordSchema = object({
  resetCode: fields.resetCodeRequired,
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
}).required();

export const accountPanelSchema = object({
  firstName: fields.firstNameRequired,
  lastName: fields.lastNameRequired,
  emailAddress: fields.emailAddressRequired.lowercase(errorMessages.lowercaseEmail),
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
  // Added on the backend.
  // contact: fields.contactRequired,
}).required();

export const addressPanelSchema = object({
  addressLine1: fields.addressLine1Required,
  city: fields.cityRequired,
  postCode: fields.postCodeRequired,
  phoneNumberPrefix: fields.phoneNumberPrefixRequired,
  phoneNumber: fields.phoneNumberRequired,
  countryName: fields.countryNameRequired,
}).required();

export const tutorInfoPanelSchema = object({
  degree: fields.degreeRequired,
  extraDegrees: fields.extraDegrees,
  school: fields.schoolRequired,
  subjectList: fields.subjectListRequired,
}).required();

// This is currently empty - it was previously used to validate the acceptance
// of the signup agreement but since the switch to Docusign there are no longer
// any fields on this step of the signup process.
export const agreementPanelSchema = object({}).required();

export const adminAccountSchema = object({
  firstName: fields.firstNameRequired,
  lastName: fields.lastNameRequired,
}).required();

export const learnerAccountSchema = object({
  firstName: fields.firstNameRequired,
  lastName: fields.lastNameRequired,
  phoneNumberPrefix: fields.phoneNumberPrefixRequired,
  phoneNumber: fields.phoneNumberRequired,
}).required();

export const parentLearnerAccountSchemaEdit = object({
  firstName: fields.firstNameRequired,
  lastName: fields.lastNameRequired,
  emailAddress: fields.emailAddressRequired.lowercase(errorMessages.lowercaseEmail),
  phoneNumberPrefix: fields.phoneNumberPrefixRequired,
  phoneNumber: fields.phoneNumberRequired,
  currentSchool: fields.currentSchoolRequired,
  previousSchools: fields.previousSchools,
  academicYear: fields.academicYear,
  academicType: fields.academicType,
  academicValue: fields.academicValue,
  subjectList: fields.subjectListRequired,
  aboutYou: string().optional(),
}).required();

export const parentLearnerAccountSchemaNew = object({
  // contact: fields.contactRequired,
  ownAccount: bool().default(true).required(),
  parent: object({
    id: string().required(),
  }).required(),
}).required().concat(parentLearnerAccountSchemaEdit);

export const learnerAccountPanelSchema = object({
  learners: array().of(object().shape({
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: string()
      .required(errorMessages.requiredField)
      .email(errorMessages.email)
      .test(
        'unique-emailAddress',
        errorMessages.uniqueEmailAddress,
        function test(value) {
          // Confirm learner has a different email address from the parent.
          const sameAsParentEmail = this.options?.context?.parentEmailAddress === value;
          // Confirm learner has a different email address from other learners.
          const existingLearnerEmails = this.options?.context?.learnerEmailAddresses
            .filter((learner, i) => i !== this.options?.index);
          const sameAsExistingLearnerEmail = existingLearnerEmails.includes(value);

          return !sameAsParentEmail && !sameAsExistingLearnerEmail;
        },
      )
      .lowercase(errorMessages.lowercaseEmail),
    phoneNumberPrefix: fields.phoneNumberPrefixRequired,
    phoneNumber: fields.phoneNumberRequired,
  })),
}).required();

export const learnerInfoSchema = object().shape({
  currentSchool: fields.currentSchoolRequired,
  previousSchools: fields.previousSchools,
  academicYear: fields.academicYear,
  academicType: fields.academicType,
  academicValue: fields.academicValue,
  subjectList: fields.subjectListRequired,
});

export const learnerInfoPanelSchema = object({
  learners: array().of(learnerInfoSchema),
}).required();

export const userTypeSchema = object({
  userType: string()
    .required(errorMessages.requiredField)
    .oneOf(['tutor', 'parent', 'learner']),
}).required();

export const signupTutorSchema = accountPanelSchema
  .concat(addressPanelSchema)
  .concat(tutorInfoPanelSchema)
  .concat(agreementPanelSchema)
  .concat(userTypeSchema);

export const signupParentSchema = accountPanelSchema
  .concat(addressPanelSchema)
  .concat(agreementPanelSchema)
  .concat(userTypeSchema);

export const signupLearnerSchema = learnerAccountPanelSchema
  .concat(learnerInfoPanelSchema);

export const updatePersonalDetailsSchema = object({
  firstName: fields.firstNameRequired,
  lastName: fields.lastNameRequired,
}).concat(addressPanelSchema);

export const updateUserSchema = object({
  firstName: fields.firstName,
  lastName: fields.lastName,
  emailAddress: fields.emailAddress,
  contact: fields.contact,
  addressLine1: fields.addressLine1,
  addressLine2: fields.addressLine2,
  city: fields.city,
  postCode: fields.postCode,
  phoneNumberPrefix: fields.phoneNumberPrefix,
  phoneNumber: fields.phoneNumber,
  countryName: fields.countryName,
  dateOfBirth: fields.dateOfBirth,
  school: fields.school,
  subjectList: fields.subjectList,
  avatar: fields.avatar,
  degree: fields.degree,
  currentSchool: fields.currentSchool,
  previousSchools: fields.previousSchools,
});

export const sessionFormSchema = object({
  startDate: date().min(dayjs().subtract(48, 'hours')).required(),
  tutors: array().of(object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  })),
  learner: object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  }),
  lessonDuration: fields.lessonDurationRequired,
  subject: object().shape({
    id: fields.idRequired,
    subjectName: fields.subjectNameRequired,
  }),
  title: fields.titleRequired,
  description: fields.description,
  callDetails: fields.callDetails,
  meetingDetails: fields.meetingDetails,
  freeTrial: fields.freeTrial,
}).required();

export const adminSessionFormSchema = object({
  tutors: array().of(object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  })),
  learner: object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  }),
  lessonDuration: fields.lessonDurationRequired,
  subject: object().shape({
    id: fields.idRequired,
    subjectName: fields.subjectNameRequired,
  }),
  title: fields.titleRequired,
  description: fields.description,
  callDetails: fields.callDetails,
  meetingDetails: fields.meetingDetails,
  freeTrial: fields.freeTrial,
}).required();

export const addSessionSchema = object({
  startDate: number().required(),
  endDate: number().required(),
  tutors: array().of(object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  })),
  learners: array().of(object().shape({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
  })),
  subject: object().shape({
    id: fields.idRequired,
    subjectName: string().required(),
  }),
  title: fields.titleRequired,
  description: fields.description,
  callDetails: fields.callDetails,
  meetingDetails: fields.callDetails,
  freeTrial: fields.freeTrial,
}).required();

export const verifyUserSchema = object({
  users: array().min(1).of(object().shape({
    id: fields.uuidRequired,
    verifyUser: fields.verifyUserRequired,
  })).required(),
});

export const getUsersSchema = object({
  attributes: array().of(string().required()).min(1).nullable(),
  userTypes: array().of(string().oneOf(['admin', 'tutor', 'parent', 'learner']).required()).min(1).nullable(),
});

export const assignLearners = object({
  learners: array().of(object({
    id: fields.uuidRequired,
    firstName: fields.firstNameRequired,
    lastName: fields.lastNameRequired,
    emailAddress: fields.emailAddressRequired,
    chargeToCustomerRate: fields.chargeToCustomerRateRequired,
    costToPathRate: fields.costToPathRateRequired,
  })).default([]).required(),
}).required();

export const updateTutorInvoiceStatusSchema = object({
  tutorId: fields.uuidRequired,
  invoiceId: fields.uuidRequired,
  status: string().oneOf(['draft', 'open', 'paid']).required(),
}).required();

const validationSchemas = {
  accountPanelSchema,
  addressPanelSchema,
  addSessionSchema,
  agreementPanelSchema,
  adminAccountSchema,
  assignLearners,
  changePasswordSchema,
  errorMessages,
  forgottenPasswordSchema,
  getUsersSchema,
  learnerAccountPanelSchema,
  learnerAccountSchema,
  learnerInfoSchema,
  learnerInfoPanelSchema,
  parentLearnerAccountSchemaEdit,
  parentLearnerAccountSchemaNew,
  passwordSchema,
  sessionFormSchema,
  signupLearnerSchema,
  signupParentSchema,
  signupTutorSchema,
  tutorInfoPanelSchema,
  updatePersonalDetailsSchema,
  updateUserSchema,
  userTypeSchema,
  verifyUserSchema,
  adminSessionFormSchema,
  costFormSchema,
};

export default validationSchemas;
