import { api, basePath } from '../core/config';
import { get, del, post } from '../core/api';

export const getSessions = async (startDate, endDate, { headers } = { headers: {} }) => get(
  api,
  `${basePath.getSession}/${startDate}/${endDate}`,
  {
    headers,
  },
);

export const cancelSession = async (body, { headers } = { headers: {} }) => del(
  api,
  basePath.cancelSession,
  {
    headers,
    body,
  },
);

export const addSession = async (formData, { headers } = { headers: {} }) => post(
  api,
  basePath.addSession,
  {
    headers,
    body: {
      formData,
    },
  },
);

export const updateSession = async (body, { headers } = { headers: {} }) => post(
  api,
  basePath.updateSession,
  {
    headers,
    body,
  },
);

export default {
  getSessions,
  addSession,
  updateSession,
  cancelSession,
};
