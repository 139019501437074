import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  alert: {
    margin: '16px 0',
    whiteSpace: 'pre-line',
  },
  form: {
    marginTop: 10,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  link: {
    color: '#162C5F',
    fontWeight: 600,
    fontSize: 14,
  },
  forgotPasswordLink: {
    color: '#162C5F',
    fontWeight: 500,
    fontSize: 14,
  },
});

export default useStyles;
