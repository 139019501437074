import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import CostIcon from '@mui/icons-material/PriceChange';
import IosShareIcon from '@mui/icons-material/IosShare';
import DataGrid from '../../components/dataGrid';
import DataGridToolbarContainer from '../../components/dataGrid/toolbar/toolbarContainer';
import DataGridToolbarSpacer from '../../components/dataGrid/toolbar/toolbarSpacer';
import DataGridToolbarButton from '../../components/dataGrid/toolbar/toolbarButton';
import PageTitle from '../../components/pageTitle';
import useStyles from './styles';
import columns from './columns';
import useLessons from './hooks/useLessons';
import EditSessionDialog from './editLessonDialog';
import EditCostDialog from './editCostDialog';
import ExportLessonsDialog from './exportLessonsDialog';

const getToolbar = ({
  selectedSession,
  openEditSessionDialog,
  openEditCostDialog,
  openExportDialog,
  loading,
}) => function () {
  return (
    <DataGridToolbarContainer>
      <DataGridToolbarSpacer />
      <DataGridToolbarButton
        startIcon={<EditIcon />}
        disabled={selectedSession === null || loading}
        onClick={openEditSessionDialog}
      >
        Edit Details
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<CostIcon />}
        disabled={selectedSession === null || loading}
        onClick={openEditCostDialog}
      >
        Edit Costs
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<IosShareIcon />}
        disabled={loading}
        onClick={openExportDialog}
      >
        Export
      </DataGridToolbarButton>

    </DataGridToolbarContainer>
  );
};

function Lessons() {
  const { classes } = useStyles();
  const {
    data,
    alert,
    loading,
    selectedSession,
    selectionModel,
    showExportDialog,
    onSelectionModelChange,
    showEditSessionDialog,
    openEditSessionDialog,
    dismissEditSessionDialog,
    submitEditSessionDialog,
    showEditCostDialog,
    openEditCostDialog,
    dismissEditCostDialog,
    submitEditCostDialog,
    openExportDialog,
    dismissExportDialog,
    tutor,
    commitChanges,
    handleEditCost,
  } = useLessons();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title="Lessons" />
      </div>
      <div className={classes.content}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.background}>
              {alert && (
                <Alert severity={alert.severity} className={classes.alert}>{alert.message}</Alert>
              )}
              <DataGrid
                className={classes.dataGrid}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'startDate', sort: 'desc' }],
                  },
                }}
                loading={loading}
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={20}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                components={{
                  Toolbar: getToolbar({
                    selectedSession,
                    openEditSessionDialog,
                    openEditCostDialog,
                    openExportDialog,
                    loading,
                  }),
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {showEditSessionDialog && (
      <EditSessionDialog
        id={selectedSession?.id}
        open={showEditSessionDialog}
        onClose={dismissEditSessionDialog}
        onCancel={dismissEditSessionDialog}
        onSubmit={submitEditSessionDialog}
        selectedSession={selectedSession}
        tutor={tutor}
        commitChanges={commitChanges}
        setAppointmentFormVisible={() => dismissEditSessionDialog()}
      />
      )}
      {showEditCostDialog && (
      <EditCostDialog
        id={selectedSession?.id}
        open={showEditCostDialog}
        onClose={dismissEditCostDialog}
        onCancel={dismissEditCostDialog}
        onSubmit={submitEditCostDialog}
        selectedSession={selectedSession}
        tutor={tutor}
        commitChanges={handleEditCost}
        setAppointmentFormVisible={() => dismissEditCostDialog()}
      />
      )}
      {showExportDialog && (
      <ExportLessonsDialog
        open={showExportDialog}
        onClose={dismissExportDialog}
        data={data}
      />
      )}
    </div>
  );
}

export default Lessons;
