import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup, FormHelperText } from '@mui/material';
import useStyles from './styles';

const FormCheckbox = ({
  control, name, inputProps, label, defaultValue, className, rules, disabled,
}) => {
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <FormControlLabel
            control={(
              <FormGroup>
                <Checkbox
                  className={classes.checkbox}
                  label={label}
                  onChange={onChange}
                  checked={value}
                  disabled={disabled}
                  {...inputProps}
                />
              </FormGroup>
        )}
            className={className}
            label={label}
          />
          {Boolean(error)
          && <FormHelperText className={classes.error}>{error.message}</FormHelperText>}
        </>
      )}
    />
  );
};

FormCheckbox.defaultProps = {
  inputProps: {},
  label: '',
  className: '',
  rules: null,
  disabled: false,
};

FormCheckbox.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  label: PropTypes.string,
  defaultValue: PropTypes.bool.isRequired,
  className: PropTypes.string,
  rules: PropTypes.shape({}),
};

export default FormCheckbox;
