import { api, basePath } from '../core/config';
import { get, post } from '../core/api';

export const getUser = async (opts = {}) => get(
  api,
  basePath.getUser,
  opts,
);

export const updateUser = async (user, opts = {}) => post(
  api,
  basePath.updateUser,
  {
    ...opts,
    body: {
      ...opts.body,
      ...user,
    },
  },
);

export const createLearner = async (learner, opts = {}) => post(
  api,
  basePath.createLearner,
  {
    ...opts,
    body: {
      ...opts.body,
      ...learner,
    },
  },
);

export const getAgreement = async (opts) => post(
  api,
  basePath.getAgreement,
  opts,
);

export default {
  getUser,
  updateUser,
  createLearner,
  getAgreement,
};
