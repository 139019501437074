import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    marginBottom: 3,
  },
  dayTitle: {
    color: '#284B75',
    fontWeight: 600,
  },
}));

export default useStyles;
