import { createTheme } from '@mui/material/styles';
import colors from './colors';

const theme = createTheme(
  {
    typography: {
      fontFamily: [
        'Montserrat',
        'sans-serif',
      ].join(','),
      fontFamilyNunito: [
        'Nunito',
        'sans-serif',
      ].join(','),
    },
    palette: {
      background: {
        default: colors.primary4,
      },
      custom: {
        ...colors,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '::-ms-clear': {
              display: 'none',
            },
            '::-ms-reveal': {
              display: 'none',
            },
            '::-webkit-search-decoration': {
              display: 'none',
            },
            '::-webkit-search-cancel-button': {
              display: 'none',
            },
            '::-webkit-search-results-button': {
              display: 'none',
            },
            '::-webkit-search-results-decoration': {
              display: 'none',
            },
          },
        },
      },
    },
  },
);

export default theme;
