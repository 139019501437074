import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { config } from '~common/utils';

const actionMap = {
  sessionAdd: 'created',
  sessionUpdate: 'updated',
  sessionCancel: 'cancelled',
  sessionDelete: 'deleted',
};

const sessionTemplate = ({
  classes, notificationData, notificationType, avatar, learnerAvatar, userType,
}) => {
  const { UserType } = config;
  const {
    startDate, tutors, learners, subject,
  } = notificationData;
  const { subjectName } = subject;
  const date = dayjs(startDate).format('DD MMMM YYYY');
  const time = dayjs(startDate).format('HH:MM');
  const action = actionMap[notificationType];
  const isParent = userType === UserType.PARENT;
  const isTutor = userType === UserType.TUTOR;

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" className={classes.author} component="div">
          <div className={classes.avatar}>{avatar}</div>
          <strong>{tutors[0].firstName} {tutors[0].lastName}</strong>
        </Typography>
      </Grid>
      {isParent && (
        <Grid item xs={12} md={6}>
          <Typography variant="body1" className={classes.author} component="div">
            <div className={classes.avatar}>{learnerAvatar}</div>
            <strong>{learners[0].firstName} {learners[0].lastName}</strong>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.message} component="div">
          Has {action} the <strong>{subjectName}</strong>{' '}
          lesson on <strong>{date}</strong> at <strong>{time}</strong>
          {(isParent || isTutor)
            && <> for <strong>{learners[0].firstName} {learners[0].lastName}</strong></>}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default sessionTemplate;
