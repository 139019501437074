import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  action: {
    display: 'flex',
    marginLeft: 'auto',
    paddingTop: 24,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
});

export default useStyles;
