import { create } from 'zustand';
import {
  deleteNotifications,
  getNotifications,
  postNotificationsSeen,
} from '~common/api';

const useNotifications = create((set, get) => ({
  notifications: {
    error: null,
    items: [],
    unseen: [],
  },
  getNotifications: async () => {
    const result = await getNotifications();

    set((state) => ({
      ...state,
      notifications: {
        ...state.notifications,
        ...(result.isOk && {
          items: result.ok.sort((a, b) => a.notificationSeq >= b.notificationSeq),
          unseen: result.ok.filter((n) => n.notificationSeen === false),
        }),
        ...(result.isError && {
          error: 'There has been an error retrieving your notifications.',
        }),
      },
    }));
  },
  deleteNotifications: async (notificationId) => {
    const result = await deleteNotifications(notificationId);

    if (result.isOk) {
      const { deleted } = result.ok;
      const { items } = get().notifications;

      set((state) => ({
        ...state,
        notifications: {
          ...state.notifications,
          items: items.filter((n) => !deleted.includes(n.notificationId)),
        },
      }));
    } else {
      set((state) => ({
        ...state,
        notifications: {
          ...state.notifications,
          error: 'There has been an error deleting this notification. Please try again or contact an administrator.',
        },
      }));
    }
  },
  postNotificationsSeen: async () => {
    const { unseen } = get().notifications;

    if (unseen.length > 0) {
      const result = await postNotificationsSeen(unseen.map((n) => n.notificationId));

      if (result.isOk) {
        set((state) => ({
          ...state,
          notifications: {
            ...state.notifications,
            unseen: [],
          },
        }));
      }
    }
  },
}));

export default useNotifications;
