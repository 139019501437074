import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import useStyles from './styles';

const FormRadioGroup = ({
  control, name, label, rules,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {label && (
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      )}
      <FormControl component="fieldset">
        <Controller
          rules={rules}
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              row
              value={value}
              onChange={(e, v) => {
                onChange(v === 'true');
              }}
            >
              <FormControlLabel
                value
                control={<Radio className={classes.control} />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio className={classes.control} />}
                label="No"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </div>
  );
};

FormRadioGroup.defaultProps = {
  label: '',
  rules: null,
};

FormRadioGroup.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.shape({}),
};

export default FormRadioGroup;
