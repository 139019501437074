import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '~common/components';
import useStyles from './styles';
import InvoiceHeader from './components/invoiceHeader';
import InvoiceLineItems from './components/invoiceLintItems';
import InvoiceNextSteps from './components/invoiceNextSteps';

const InvoicePayedDialog = ({
  invoice,
  open,
  onClose,
  onCancel,
  onSubmit,
}) => {
  const { classes } = useStyles();
  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle className={classes.title}>
          Review Invoice
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InvoiceHeader invoice={invoice} />
          <InvoiceLineItems invoice={invoice} />
          <InvoiceNextSteps status={invoice.status} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSubmit('open', invoice)} disabled={!(invoice.status === 'draft')}>
            Mark as OPEN
          </Button>
          <Button variant="contained" onClick={() => onSubmit('paid', invoice)} disabled={!(invoice.status === 'open')}>
            Mark as PAID
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

InvoicePayedDialog.defaultProps = {
  onClose: () => { },
  onCancel: () => { },
  onSubmit: () => { },
};

InvoicePayedDialog.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    metadata: PropTypes.shape({
      tutor: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    total: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default InvoicePayedDialog;
