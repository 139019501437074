import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { Button } from '~common/components';
import Grid from '@mui/material/Grid';
import * as XLSX from 'xlsx';
import useStyles from './styles';
import { getStatus } from '../columns';

function exportAndDownload(data, startDate, endDate) {
  const selectedLessons = data.filter((lesson) => lesson.startDate >= startDate.valueOf()
    && lesson.startDate <= endDate.valueOf())
    .map((lesson) => ({
      id: lesson.id,
      startDate: dayjs(lesson.startDate)
        .format('DD-MM-YYYY HH:mm'),
      duration: dayjs(lesson.endDate).diff(dayjs(lesson.startDate), 'hour', true),
      status: getStatus(lesson.status, lesson.endDate),
      tutor: lesson.tutors.map((obj) => `${obj.firstName} ${obj.lastName}`)
        .join(', '),
      learners: lesson.learners.map((obj) => `${obj.fullName}`)
        .join(', '),
      parent: lesson.learners.length === 1 && lesson.learners[0].parent
        ? `${lesson.learners[0].parent.firstName} ${lesson.learners[0].parent.lastName}` : '',
      subject: lesson.subject.subjectName,
      title: lesson.title,
      callDetails: lesson.callDetails,
      description: lesson.description,
      chargeToCustomer: lesson.chargeToCustomer ? (lesson.chargeToCustomer ?? 0) / 100 : '',
      chargeToPath: lesson.chargeToPath ? (lesson.chargeToPath ?? 0) / 100 : '',
      freeTrial: lesson.freeTrial,
    }));

  selectedLessons.sort((a, b) => a.startDate - b.startDate);

  // Add headers to the data
  const headers = {
    id: 'ID',
    startDate: 'Start Date',
    duration: 'Duration',
    status: 'Status',
    tutor: 'Tutor(s)',
    learners: 'Learner(s)',
    parent: 'Parent',
    subject: 'Subject',
    title: 'Title',
    callDetails: 'Call Details',
    description: 'Description',
    chargeToCustomer: 'Charge to Customer',
    chargeToPath: 'Charge to Path',
    freeTrial: 'Free Trial',
  };
  const output = [headers, ...selectedLessons];

  const worksheet = XLSX.utils.json_to_sheet(output, { skipHeader: true });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Lessons');
  XLSX.writeFile(workbook, `Lessons Export ${startDate.format('DD-MM-YYYY')} - ${endDate.format('DD-MM-YYYY')}.xlsx`);
}

const ExportLessonsDialog = ({
  open,
  onClose,
  data,
}) => {
  const { classes } = useStyles();

  const [startDate, setStartDate] = useState(dayjs()
    .startOf('month'));
  const [endDate, setEndDate] = useState(dayjs()
    .endOf('month'));

  useEffect(() => {
    const lowestStartDate = Math.min(...data.map((lesson) => lesson.startDate));
    const highestEndDate = Math.max(...data.map((lesson) => lesson.startDate));
    setStartDate(dayjs(lowestStartDate));
    setEndDate(dayjs(highestEndDate));
  }, [data]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title}>
          Export Lessons
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid className={classes.content} container direction="column" spacing={1}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}><DesktopDatePicker
              renderInput={
                // eslint-disable-next-line max-len
                (params) => <TextField required {...params} />
              }
              format="DD/MM/YYYY"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={setStartDate}
            />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}><DesktopDatePicker
              renderInput={
                // eslint-disable-next-line max-len
                (params) => <TextField required {...params} />
              }
              format="DD/MM/YYYY"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={setEndDate}
            />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                exportAndDownload(data, startDate, endDate);
              }}
            >Export
            </Button>
          </Grid>
        </Grid>
        <DialogContent />
      </Dialog>
    </div>
  );
};

ExportLessonsDialog.defaultProps = {
  onClose: () => {
  },
};

ExportLessonsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ExportLessonsDialog;
