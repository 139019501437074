import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'columns',
    backgroundColor: theme.palette.custom.primary1,
    border: `1px solid ${theme.palette.custom.primary1}`,
    borderRadius: '4px',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  icon: {
    display: 'inline-block',
    maxWidth: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(1),
  },
  text: {
    display: 'inline-block',
    paddingRight: '2px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  user: {
    fontSize: 12,
    textTransform: 'capitalize',
  },
  info: {
    fontSize: 10,
  },
}));

const AssumedModeIndicator = ({ isAssumedMode, userData }) => {
  const { classes } = useStyles();

  return isAssumedMode ? (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <SwitchAccountIcon />
      </Box>
      <Box className={classes.text}>
        <Typography
          className={classes.title}
          variant="body2"
          component="div"
        >
          ASSUMED USER MODE
        </Typography>
        <Typography
          className={classes.user}
          variant="body2"
          component="div"
        >
          {userData.fullName} ({userData.userType})
        </Typography>
        <Typography
          className={classes.info}
          variant="caption"
          component="div"
        >
          {userData.emailAddress}
        </Typography>
      </Box>
    </Box>
  ) : null;
};

AssumedModeIndicator.defaultProps = {
  userData: {
    fullName: '',
    userType: '',
    emailAddress: '',
  },
};

AssumedModeIndicator.propTypes = {
  isAssumedMode: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    fullName: PropTypes.string,
    userType: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
};

export default AssumedModeIndicator;
