import {
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  filter: {
    backgroundColor: theme.palette.custom.primary1,
    border: `1px solid ${theme.palette.custom.primary1}`,
    borderRadius: '5px',
    color: 'white',
    padding: '0px',
    paddingTop: '2px',
    height: '38px',

    '&:disabled': {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.custom.secondary3}`,
    },

    '& .MuiInput-input::placeholder': {
      color: 'white',
      opacity: 0.52,
    },
    '& .MuiInput-input': {
      color: 'white',
      opacity: 1,
    },

    '& .MuiSvgIcon-root': {
      color: 'white',
      paddingLeft: '3px',
      width: '24px',
      height: '24px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '0px',
    },
  },
}));

const DataGridToolbarQuickFilter = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <GridToolbarQuickFilter className={classes.filter} {...props} />
  );
};

export default DataGridToolbarQuickFilter;
