import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CalendarIcon from '@mui/icons-material/CalendarTodayRounded';
import CloseIcon from '@mui/icons-material/Close';

import { Grid } from '@mui/material';
import AvatarImage from '../AvatarImage';
import templates from './templates';
import useStyles from './styles';

const renderAvatar = (notification, user, avatarUriPrefix, userField) => {
  const { userType } = user;
  const { notificationData, notificationType } = notification;

  if (userType !== 'admin' && ['sessionAdd', 'sessionDelete', 'sessionUpdate'].includes(notificationType)) {
    return <AvatarImage size="s" src={`${avatarUriPrefix}/${notificationData[userField][0].id}`} />;
  }

  return null;
};

const renderNotificationMessage = (classes, notification, user, avatarUriPrefix) => {
  const { userType } = user;
  const { notificationData, notificationType } = notification;
  const templateFn = templates[notificationType];

  return templateFn ? templateFn({
    classes,
    notificationType,
    notificationData,
    userType,
    avatar: renderAvatar(notification, user, avatarUriPrefix, 'tutors'),
    learnerAvatar: renderAvatar(notification, user, avatarUriPrefix, 'learners'),
  }) : null;
};

const NotificationListItem = ({
  user,
  notification,
  onDelete,
  avatarUriPrefix,
}) => {
  const { classes } = useStyles();
  const { notificationId, notificationSeq } = notification;

  return (
    <ListItem className={classes.listItem}>
      <Grid container>
        <Grid xs={12} md={4} item className={classes.listItemIcon}>
          <ListItemIcon>
            <CalendarIcon className={classes.calendarIcon} />
            <div className={classes.dateTime}>
              <Typography className={classes.date} variant="body1">
                {dayjs(notificationSeq).format('DD MMM, YYYY')}
              </Typography>
              <Typography className={classes.time} variant="body1">
                {dayjs(notificationSeq).format('HH:mm')}
              </Typography>
            </div>
          </ListItemIcon>
        </Grid>
        <Grid xs={12} md={8} item>
          <ListItemText className={classes.listItemText}>
            <div className={classes.message}>
              {renderNotificationMessage(classes, notification, user, avatarUriPrefix)}
            </div>
          </ListItemText>
        </Grid>
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <IconButton className={classes.closeIconButton} onClick={() => onDelete(notificationId)}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </ListItemSecondaryAction>
      </Grid>
    </ListItem>
  );
};

NotificationListItem.defaultProps = {
  avatarUriPrefix: null,
};

NotificationListItem.propTypes = {
  notification: PropTypes.shape({
    notificationId: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    notificationSeq: PropTypes.number.isRequired,
    notificationUserId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    notificationData: PropTypes.object.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  avatarUriPrefix: PropTypes.string,
};

export default NotificationListItem;
