import { useEffect } from 'react';
import useNotifcations from '../../../../../store/notificationsStore';

const notificationsSelector = (state) => ({
  notifications: state.notifications,
  getNotifications: state.getNotifications,
  deleteNotifications: state.deleteNotifications,
  postNotificationsSeen: state.postNotificationsSeen,
});

let intervalId;

const useNotificationsBell = () => {
  const { notifications, getNotifications } = useNotifcations(notificationsSelector);

  useEffect(() => {
    if (!intervalId) {
      // Call immediate so that we can update the UI
      getNotifications();

      // Create the interval to fetch for new notifications
      intervalId = setInterval(() => {
        getNotifications();
      }, 60000);
    }

    // When the component unmounts clear out the interval timer
    return () => {
      clearInterval(intervalId);
      intervalId = undefined;
    };
  }, []);

  return {
    unseen: notifications.unseen,
    error: notifications.error,
  };
};

export default useNotificationsBell;
