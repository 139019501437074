/* eslint-disable import/no-unresolved,import/extensions */
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Grid,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { costFormSchema } from '~common/utils/validation/validationSchemas';
import FormTextField from '~common/components/form/formTextField';
import Button from '~common/components/button';
import useStyles from './styles';

const EditCostDialog = ({
  open,
  onClose,
  selectedSession,
  commitChanges,
}) => {
  const { classes } = useStyles();

  const displayValues = {
    chargeToCustomer: selectedSession.cost.chargeToCustomer / 100,
    chargeToPath: selectedSession.cost.chargeToPath / 100,
  };
  const defaultValues = {
    ...displayValues,
  };

  const saveData = async (data) => {
    const costAsPence = {
      chargeToCustomer: data.chargeToCustomer * 100,
      chargeToPath: data.chargeToPath * 100,
    };

    const changed = {
      ...selectedSession,
      cost: {
        ...costAsPence,
      },
    };
    await commitChanges(changed);
    onClose();
  };

  const {
    handleSubmit, control, formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(costFormSchema),
  });

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md" scroll="paper" fullWidth disableEscapeKeyDown>
        <DialogTitle className={classes.title}>
          Edit Costs
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(async (data) => saveData(data))}
            className={classes.formPanel}
          >
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <FormTextField
                  name="chargeToPath"
                  control={control}
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  inputProps={{
                    label: 'Tutor Cost',
                    autoFocus: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="chargeToCustomer"
                  control={control}
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  inputProps={{
                    label: 'Customer Pays',
                    autoFocus: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.actionButtons}>
                <Button
                  onClick={() => { onClose(); }}
                  type="button"
                  variant="outlined"
                >
                  Cancel Changes
                </Button>
                <Button className={classes.actionButton} disabled={!isDirty} type="submit">Save Changes</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditCostDialog.defaultProps = {
  onClose: () => { },
};

EditCostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  selectedSession: PropTypes.shape({
    cost: PropTypes.shape({
      chargeToCustomer: PropTypes.number,
      chargeToPath: PropTypes.number,
    }).isRequired,
  }).isRequired,
  tutor: PropTypes.shape({
    learners: PropTypes.arrayOf(PropTypes.shape({})),
    subjectList: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  commitChanges: PropTypes.func.isRequired,
};

export default EditCostDialog;
