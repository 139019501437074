import Typography from '@mui/material/Typography';

const learnerSignupTemplate = ({ classes, notificationData, userType }) => {
  const { firstName, lastName, parent } = notificationData;

  const message = userType === 'admin'
    ? (
      <>
        <strong>{parent.firstName} {parent.lastName}</strong> has <strong>signed up</strong> and
        {' '} has enroled <strong>{firstName} {lastName}</strong>.
      </>
    )
    : (
      <>
        Welcome to Path Education, <strong>{firstName} {lastName}</strong>.<br />

        <strong>{parent.firstName} {parent.lastName}</strong> has enrolled you for tuition. A tutor
        will be assigned to you shortly.
      </>
    );

  return (
    <>
      <Typography variant="body1" className={classes.author} component="div">
        <strong>Path Education</strong>
      </Typography>
      <Typography variant="body1" className={classes.message} component="div">
        {message}
      </Typography>
    </>
  );
};

export default learnerSignupTemplate;
