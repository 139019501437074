import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'inline-flex',
    flexGrow: 1,
  },
}));

const DataGridToolbarSpacer = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root} {...props} />
  );
};

export default DataGridToolbarSpacer;
