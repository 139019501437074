import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    minHeight: '78px',
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  date: {
    lineHeight: '21.82px',
    color: theme.palette.custom.primary1,
    fontWeight: 600,
  },
  time: {
    lineHeight: '19.1px',
    color: theme.palette.custom.primary1,
  },
  dateTime: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        marginRight: 5,
      },
    },
  },
  calendarIcon: {
    color: theme.palette.custom.primary1,
    width: '20px',
    height: '20px',
    marginRight: 5,
  },
  closeIconButton: {
    marginRight: theme.spacing(2),
  },
  closeIcon: {
    width: '25px',
    height: '25px',
  },
  author: {
    color: theme.palette.custom.primary1,
    marginTop: theme.spacing(0.6),
  },
  avatar: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginRight: 5,
  },
  message: {
    color: theme.palette.custom.primary1,
    marginTop: theme.spacing(2),
  },
  listItemIcon: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    marginRight: 'auto',
  },
  listItemSecondaryAction: {
    position: 'absolute',
    top: 32,
    right: 0,
  },
}));

export default useStyles;
