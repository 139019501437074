import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  checkbox: {
    color: 'rgba(150, 150, 150, 0.6)',
    '&.Mui-checked': {
      color: '#162C5F',
    },
  },
  error: {
    paddingLeft: 32,
    paddingBottom: 5,
    color: 'rgb(211, 47, 47)',
  },
});

export default useStyles;
