import PropTypes from 'prop-types';
import UnverifiedCard from '../components/unverifiedCard';

const PrivateRoute = ({
  children,
  user,
  handleSignOut,
  loading,
}) => {
  const { isAuthenticated, isVerified, userType } = user;

  if (!isAuthenticated || !userType) {
    const loginUrl = import.meta.env.VITE_LOGIN_DOMAIN;
    window.location.replace(loginUrl);

    return null;
  }

  if (!loading && user.userType !== 'admin') {
    window.location.replace(import.meta.env.VITE_DASHBOARD_DOMAIN);
    return null;
  }

  if (!isVerified) {
    return (
      <UnverifiedCard handleSignOut={handleSignOut} />
    );
  }

  return children;
};

PrivateRoute.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    isVerified: PropTypes.bool,
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PrivateRoute;
