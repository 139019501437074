import { useUserStore } from '~common/store';

export const userSelector = (state) => ({
  user: state.user,
  getUser: state.getUser,
  updateUser: state.updateUser,
  error: state.error,
});

export default useUserStore;
