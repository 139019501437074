import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '~common/components';
import useStyles from './styles';

const ResetPasswordDialog = ({
  open,
  onClose,
  onCancel,
  onSubmit,
}) => {
  const { classes } = useStyles();
  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md" scroll="paper" fullWidth disableEscapeKeyDown>
        <DialogTitle className={classes.title}>
          Confirm Password Reset
          <IconButton disableFocusRipple className={classes.titleIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm you want to reset password for user
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>Cancel</Button>
          <Button variant="contained" onClick={onSubmit}>Reset Password</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ResetPasswordDialog.defaultProps = {
  onClose: () => { },
  onCancel: () => { },
  onSubmit: () => { },
};

ResetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ResetPasswordDialog;
