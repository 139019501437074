import { useEffect, useState, useCallback } from 'react';
import { getAllTutorInvoices, udpateTutorInvoiceStatus } from '~common/api';

export default () => {
  const [state, setState] = useState({
    invoices: [],
    loading: true,
    error: null,
    alert: null,
    selectionModel: [],
    selectedInvoice: null,
    showReviewInvoiceDailog: false,
  });

  const handleGetTutorInvoices = async () => {
    const result = await getAllTutorInvoices();

    setState((prevState) => ({
      ...prevState,
      loading: false,
      invoices: result?.ok?.invoices ?? [],
      error: result.isError
        ? 'There has been an error fetching tutor invoices please try again later.'
        : null,
    }));
  };

  const onSelectionModelChange = useCallback((selectionModel) => {
    const invoiceId = selectionModel[0];
    const selectedInvoice = state.invoices
      ? state.invoices?.find((invoice) => invoice.id === invoiceId)
      : null;

    setState((prevState) => ({
      ...prevState,
      selectionModel,
      selectedInvoice,
      error: null,
    }));
  }, [state.invoices]);

  const onRefreshClick = useCallback(() => {
    setState({
      invoices: [],
      loading: true,
      error: null,
      selectionModel: [],
      selectedInvoice: null,
    });
    handleGetTutorInvoices();
  });

  const onReviewInvoiceClick = useCallback(() => {
    if (state.selectedInvoice) {
      setState((prevState) => ({
        ...prevState,
        showReviewInvoiceDailog: true,
        error: null,
      }));
    }
  }, [state.selectedInvoice]);

  const onReviewInvoiceDailogClose = () => {
    setState((prevState) => ({
      ...prevState,
      showReviewInvoiceDailog: false,
    }));
  };

  const onReviewInvoiceDialogSubmit = useCallback(async (status, invoice) => {
    const result = await udpateTutorInvoiceStatus({
      invoiceId: invoice.id,
      tutorId: invoice.metadata.tutor.id,
      status,
    });

    setState((prevState) => ({
      ...prevState,
      ...(result.isOk && {
        invoices: prevState.invoices.map((inv) => (
          inv.id === invoice.id ? result.ok.result : inv
        )),
      }),
      error: result.isError
        ? `There has been an error updating tutor invoice ${invoice.id} for ${invoice.metadata.tutor.firstName} ${invoice.metadata.tutor.lastName}`
        : null,
      showReviewInvoiceDailog: !result.isError,
    }));
  }, [state]);

  useEffect(() => {
    handleGetTutorInvoices();
  }, []);

  return {
    ...state,
    onSelectionModelChange,
    onRefreshClick,
    onReviewInvoiceClick,
    onReviewInvoiceDailogClose,
    onReviewInvoiceDialogSubmit,
  };
};
