import { useState } from 'react';
import { API } from '@aws-amplify/api';
import { config } from '~common/utils';
import { verifyUser } from '~common/api';

const useManageUsers = () => {
  const { apiName } = config;
  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const getUsers = async () => {
    const path = config.path.getTutors;

    try {
      const res = await API.get(apiName, path);
      setData(res);
      setLoading(false);
    } catch (e) {
      setError('There has been an error while retrieving user information.');
    }
  };

  const verifyUsers = async () => {
    const users = selectedUsers.map((id) => ({ id, verifyUser: true }));

    setSuccess(null);
    setError(null);

    try {
      await verifyUser({ users });
      getUsers();
      setSuccess('Users verified.');
    } catch (e) {
      setError('There has been an error verifying users.');
    }
  };

  return {
    data,
    getUsers,
    verifyUsers,
    isVerifyDisabled: selectedUsers.length === 0,
    setSelectedUsers,
    loading,
    error,
    success,
  };
};

export default useManageUsers;
