import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginLeft: 'auto',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
  },
}));

export default useStyles;
