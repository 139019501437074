import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const InvoiceNextSteps = ({ status }) => (
  <ul style={{ padding: 8 }}>
    {status === 'draft' && (
      <>
        <li>
          <Typography variant="body2">
            This invoice can be marked as <strong>OPEN</strong> and thus ready for payment
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Once marked as <strong>OPEN</strong> newly completed sessions
            will automatically be added to a new draft invoice
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Upon confirmation the tutor has received payment you can return to
            this dialog and mark the invoice as <strong>PAID</strong>
          </Typography>
        </li>
      </>
    )}
    {status === 'open' && (
      <>
        <li>
          <Typography variant="body2">
            This invoice can be marked as <strong>PAID</strong>
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Once marked as <strong>PAID</strong> you are confirming the
            tutor has received payment
          </Typography>
        </li>
      </>
    )}
    {status === 'paid' && (
      <>
        <li>
          <Typography variant="body2">
            This invoice has been confirmed as <strong>PAID</strong>
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            No further action required
          </Typography>
        </li>
      </>
    )}
  </ul>
);

InvoiceNextSteps.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InvoiceNextSteps;
