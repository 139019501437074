import dayjs from 'dayjs';

export const formatInvoiceDate = (date) => date && dayjs(date)
  .format('YYYY-MM-DD HH:mm');

// Helper for displaying the times that a session took place. We don't have start and end date saved
// as metadata, so instead have to calculate the end date based on the duration. This currently
// assumes that a session always takes place on the same day.
export const formatForInvoiceDisplay = (date, duration) => {
  if (!duration) {
    return `${dayjs(date).format('DD/MM/YY HH:mm')} - N/A`;
  }
  return `${dayjs(date).format('DD/MM/YY HH:mm')} - ${dayjs(date).add(duration, 'hour').format('HH:mm')}`;
};

export const formatAmount = (amount, currency) => {
  const currencyformatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

  return currencyformatter.format(
    amount === 0
      ? amount
      : amount / 100,
  );
};
