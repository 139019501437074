import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import useStyles from './styles';

const DataGrid = ({ rows, columns, ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        {...props}
      />
    </div>
  );
};

DataGrid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      emailAddress: PropTypes.string,
      verified: PropTypes.bool,
    }),
  ).isRequired,
};

export default DataGrid;
