import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';

import useStyles from './styles';

const AvatarImage = ({
  onClick,
  src,
  size,
  borderColor,
}) => {
  const { classes } = useStyles({ size, borderColor, onClick });
  const [srcLoadErr, setSrcLoadErr] = useState();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Tooltip title={onClick ? 'Click to upload avatar' : null} placement="top">
          <Box className={classes.avatar}>
            {(srcLoadErr || !src)
              ? <AccountCircle className={classes.noavatar} />
              : (
                <img
                  className={classes.image}
                  src={src}
                  alt=""
                  onError={(e) => {
                    e.target.error = null;
                    setSrcLoadErr(true);
                  }}
                />
              )}
            <Box className={classes.overlay} onClick={(e) => onClick && onClick(e)} />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

AvatarImage.defaultProps = {
  onClick: null,
  src: null,
  size: 'large',
  borderColor: 'white',
};

AvatarImage.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'large']),
  borderColor: PropTypes.string,
};

export default AvatarImage;
