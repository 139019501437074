import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { useEffect } from 'react';
import { formatFieldName } from '@patheducation/login/src/components/registrationForm/helpers';
import FormTextField from '../formTextField';
import FormAutocomplete from '../formAutocomplete';
import FormDatePicker from '../formDatePicker';
import useStyles from './styles';
import dataLists from '../../../utils/dataLists';

const {
  countryCodeOptions,
  countryOptions,
} = dataLists;

const FormAddressFields = ({
  control, getValues, setValue, userType,
}) => {
  const { classes } = useStyles();

  useEffect(() => {
    setValue('phoneNumberPrefix', {
      id: 'UK',
      value: '+44',
      name: 'United Kingdom',
    });
  }, []);

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12}>
        <FormTextField
          name="addressLine1"
          control={control}
          inputProps={{
            label: 'Address Line 1',
            autoFocus: true,
            margin: 'dense',
            required: true,
          }}
        />
        <FormTextField
          name="addressLine2"
          control={control}
          inputProps={{
            label: 'Address Line 2',
            margin: 'dense',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="city"
          control={control}
          inputProps={{
            label: 'City',
            margin: 'dense',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="postCode"
          control={control}
          inputProps={{
            label: 'Post Code',
            margin: 'dense',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={5} sm={4}>
        <FormAutocomplete
          name="phoneNumberPrefix"
          control={control}
          className={classes.prefix}
          options={countryCodeOptions}
          disableClearable
          getOptionLabel={(option) => option.value}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              ({option.value}) {option.name}
            </li>
          )}
          defaultValue={getValues('phoneNumberPrefix')}
          inputProps={{
            placeholder: '+44',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={7} sm={8}>
        <FormTextField
          name="phoneNumber"
          control={control}
          inputProps={{
            label: 'Number',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          name="countryName"
          control={control}
          options={countryOptions}
          getOptionLabel={(option) => option.value}
          label="Country"
          defaultValue={getValues('countryName')}
          inputProps={{
            label: 'Please Choose',
            required: true,
          }}
        />
      </Grid>
      {userType === 'tutor' && (
      <Grid item xs={12} sm={6} className={classes.date}>
        <FormDatePicker
          name="dateOfBirth"
          control={control}
          inputProps={{
            label: 'Date of Birth',
            disableFuture: true,
          }}
          required
        />

      </Grid>
      )}
    </Grid>
  );
};

FormAddressFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

export default FormAddressFields;
