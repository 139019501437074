import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const FormSwitch = ({
  control, name, inputProps, label, defaultValue,
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        control={(
          <Switch
            onChange={(_, checked) => onChange(checked)}
            checked={value}
            {...inputProps}
          />
          )}
        label={label}
      />
    )}
  />
);
FormSwitch.defaultProps = {
  inputProps: {},
  label: '',
};

FormSwitch.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.shape({}),
  label: PropTypes.string,
  defaultValue: PropTypes.bool.isRequired,
};

export default FormSwitch;
