class Result extends Array {
  #isError;

  constructor(ok, error) {
    super(ok, error);

    this.#isError = !!error;
  }

  get ok() {
    return this[0];
  }

  set ok(value) {
    this[0] = value;
    this.#isError = false;
  }

  get error() {
    return this[1];
  }

  set error(error) {
    this[1] = error;
    this.#isError = true;
  }

  get isError() {
    return this.#isError;
  }

  get isOk() {
    return !this.#isError;
  }
}

export default Result;
