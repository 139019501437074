import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormTextField from '../formTextField';
import FormAutocomplete from '../formAutocomplete';
import useStyles from './styles';
import dataLists from '../../../utils/dataLists';
import Button from '../../button';
import SubjectsField from '../formSubjectsField';

const {
  schoolOptions,
  subjectOptions,
  qualificationsOptions,
  examBoardOptions,
  academicYearOptions,
  academicValuesOptions,
  academicTypeOptions,
} = dataLists;

const FormLearnerInfoFields = ({
  control,
  getValues,
  watch,
  readOnly,
  setValue,
  trigger,
  type,
}) => {
  const { classes } = useStyles();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'subjectList',
  });
  const watchSubjectList = watch('subjectList');
  const controlledSubjectFields = fields.map((field, index) => ({
    ...field,
    ...watchSubjectList[index],
  }));

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormAutocomplete
            name="currentSchool"
            control={control}
            options={schoolOptions}
            getOptionLabel={(option) => option}
            label="What school do they currently attend?"
            defaultValue={getValues('school.name')}
            inputProps={{
              label: 'Please Choose',
              type: 'search',
            }}
            readOnly={readOnly}
            freeSolo
            handleHomeEndKeys
          />
        </Grid>
        <>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.label}>
              What Academic Year are they currently in?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormAutocomplete
              name="academicType"
              control={control}
              options={academicTypeOptions}
              getOptionLabel={(option) => (option ? option.value : '')}
              defaultValue={getValues('academicType')}
              inputProps={{
                label: 'Please Choose',
              }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <FormAutocomplete
              name="academicValue"
              control={control}
              options={academicValuesOptions}
              getOptionLabel={(option) => (option ? option.value : '')}
              defaultValue={getValues('academicValue')}
              inputProps={{
                label: 'Please Choose',
              }}
              readOnly={readOnly}
            />
          </Grid>
        </>
      </Grid>

      <Typography variant="body1" className={classes.label}>Which subjects do you require a tutor? </Typography>

      {/* Dynamic subject fields */}
      {/* {controlledSubjectFields.map((item, index) => ( */}
      {/*   <Grid container spacing={1} key={item.id}> */}
      {/*     <Grid item xs={11} className={classes.subjectFields}> */}
      {/*       <FormAutocomplete */}
      {/*         name={`subjectList.${index}.qualificationLevel`} */}
      {/*         defaultValue={getValues(`subjectList.${index}.qualificationLevel`)} */}
      {/*         control={control} */}
      {/*         options={qualificationsOptions} */}
      {/*         getOptionLabel={(option) => option.qualificationLevel} */}
      {/*         inputProps={{ */}
      {/*           label: 'Qualification Level', */}
      {/*         }} */}
      {/*         readOnly */}
      {/*       /> */}

      {/*     </Grid> */}
      {/*     /!* Prevent the user from removing all subject fields *!/ */}
      {/*     <Grid item xs={1}> */}
      {/*       <IconButton className={classes.deleteIcon} onClick={() => remove(index)}> */}
      {/*         <ClearIcon /> */}
      {/*       </IconButton> */}
      {/*     </Grid> */}
      {/*     /!* TODO: Only allow selection of listed qualification level with a matching subject. *!/ */}
      {/*     <Grid item xs={12} sm={6}> */}
      {/*       <FormAutocomplete */}
      {/*         name={`subjectList.${index}.subject`} */}
      {/*         defaultValue={getValues(`subjectList.${index}.subject`)} */}
      {/*         control={control} */}
      {/*         options={subjectOptions} */}
      {/*         getOptionLabel={(option) => option.subjectName} */}
      {/*         label={index === 0 ? '' : ''} */}
      {/*         inputProps={{ */}
      {/*           label: 'Subject', */}
      {/*         }} */}
      {/*         readOnly */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*     <Grid item xs={12} sm={6}> */}
      {/*       <FormAutocomplete */}
      {/*         name={`subjectList.${index}.examBoard`} */}
      {/*         defaultValue={getValues(`subjectList.${index}.examBoard`)} */}
      {/*         control={control} */}
      {/*         options={examBoardOptions} */}
      {/*         getOptionLabel={(option) => option.examBoardName} */}
      {/*         inputProps={{ */}
      {/*           label: 'Exam Board (Optional)', */}
      {/*         }} */}
      {/*         readOnly */}
      {/*       /> */}
      {/*     </Grid> */}
      {/*   </Grid> */}
      {/* ))} */}
      {/* {!readOnly && ( */}
      {/* <div className={classes.addButton}> */}
      {/*   <Button */}
      {/*     onClick={() => append({ */}
      {/*       subject: null, */}
      {/*       qualificationLevel: null, */}
      {/*       examBoard: null, */}
      {/*     })} */}
      {/*     variant="outlined" */}
      {/*     endIcon={<AddIcon />} */}
      {/*   > */}
      {/*     Add another Subject */}
      {/*   </Button> */}
      {/* </div> */}
      {/* )} */}

      <SubjectsField
        control={control}
        getValues={getValues}
        setValue={setValue}
        trigger={trigger}
      />

      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          <FormTextField
            name="aboutYou"
            control={control}
            label="Any Additional information"
            inputProps={{
              multiline: true,
              rows: 7,
              margin: 'dense',
              placeholder: `Please tell us more about the learner...
              - Favourite subjects
              - Extra-curricular activities
              - Educational aspirations`,
            }}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormLearnerInfoFields.defaultProps = {
  readOnly: false,
};

FormLearnerInfoFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(['parentLearnerCreate', 'parentLearnerEdit']).isRequired,
};

export default FormLearnerInfoFields;
