const colors = {
  primary1: '#162C5F',
  primary2: '#2C416F',
  primary3: '#939AAC',
  primary4: '#F5F6FA',
  secondary1: '#323232',
  secondary2: '#969696',
  secondary3: '#B5B5BE',
  secondary4: '#F2F2F2',
  secondary5: '#F8F8FF',
  accent1: '#348D50',
  accent2: '#604BBA',
  accent3: '#FF562D',
  accent4: '#0062FF',
  accent5: '#F82929',
};

export default colors;

export const userColors = [
  colors.accent1,
  colors.accent2,
  colors.accent3,
  colors.accent4,
];
