import dayjs from 'dayjs';
import {
  formatInvoiceDate,
  formatAmount,
} from './helpers';

export default [
  {
    field: 'tutor',
    headerName: 'Tutor',
    flex: 2,
    valueGetter: ({ row }) => `${row.metadata.tutor.firstName} ${row.metadata.tutor.lastName}`,
  },
  {
    field: 'id',
    headerName: 'Invoice Id',
    flex: 2,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 165,
    valueGetter: ({ row }) => formatInvoiceDate(row.createdAt),
    valueFormatter: ({ value }) => dayjs(value).format('DD-MM-YYYY HH:mm'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 165,
    valueGetter: ({ row }) => formatInvoiceDate(row.updatedAt),
    valueFormatter: ({ value }) => dayjs(value).format('DD-MM-YYYY HH:mm'),
  },
  {
    field: 'lineItemsCount',
    headerName: 'Line Items',
    width: 125,
    align: 'right',
    headerAlign: 'right',
    valueGetter: ({ row }) => row.lines?.length ?? 'None',
  },
  {
    field: 'totalHours',
    headerName: 'Total Hours',
    width: 125,
    align: 'right',
    headerAlign: 'right',
    valueGetter: ({ row }) => row.metadata.totalHoursTutored ?? 'Unset',
  },
  {
    field: 'total',
    headerName: 'Total (Currency)',
    align: 'right',
    width: 175,
    headerAlign: 'right',
    valueGetter: ({ row }) => formatAmount(row.total, row.currency),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 175,
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) => row.status.toUpperCase(),
  },
];
