import { create } from 'zustand';
import { getParentInvoices } from '~common/api';

const useParentInvoicesStore = create((set) => ({
  invoices: [],
  getParentInvoices: async () => {
    const result = await getParentInvoices();

    if (result.isOk) {
      set((state) => ({
        ...state,
        invoices: result.ok,
      }));
    }
    return result;
  },
}));

export default useParentInvoicesStore;
