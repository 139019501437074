import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import ViewInvoiceIcon from '@mui/icons-material/Description';
import PageTitle from '../../components/pageTitle';
import DataGrid from '../../components/dataGrid';
import DataGridToolbarContainer from '../../components/dataGrid/toolbar/toolbarContainer';
import DataGridToolbarButton from '../../components/dataGrid/toolbar/toolbarButton';
import DataGridToolbarQuickFilter from '../../components/dataGrid/toolbar/toolbarQuickFilter';
import DataGridToolbarSpacer from '../../components/dataGrid/toolbar/toolbarSpacer';
import DataGridToolbarRefreshButton from '../../components/dataGrid/toolbar/toolbarButtons/toolbarRefreshButton';

import ReviewInvoiceDialog from './components/reviewInvoiceDialog';
import useTutorInvoices from './hooks/useTutorInvoices';
import useStyles from './styles';
import columns from './columns';

const getToolbar = ({
  selectedInvoice,
  onRefreshClick,
  onReviewInvoiceClick,
}) => () => (
  <DataGridToolbarContainer>
    <DataGridToolbarRefreshButton onClick={onRefreshClick} />
    <DataGridToolbarQuickFilter />
    <DataGridToolbarSpacer />
    <DataGridToolbarButton
      startIcon={<ViewInvoiceIcon />}
      disabled={!(selectedInvoice)}
      onClick={onReviewInvoiceClick}
    >
      Review Invoice
    </DataGridToolbarButton>
  </DataGridToolbarContainer>
);

function TutorInvoices() {
  const { classes } = useStyles();
  const {
    invoices,
    loading,
    error,
    selectedInvoice,
    selectionModel,
    onSelectionModelChange,
    onRefreshClick,
    onReviewInvoiceClick,
    onReviewInvoiceDailogClose,
    onReviewInvoiceDialogSubmit,
    showReviewInvoiceDailog,
  } = useTutorInvoices();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title="Tutor Invoices" />
      </div>
      <div className={classes.content}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.background}>
              {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
              <DataGrid
                className={classes.dataGrid}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'createdAt', sort: 'desc' }],
                  },
                }}
                loading={loading}
                rows={invoices}
                columns={columns}
                getRowId={(row) => row.id}
                components={{
                  Toolbar: getToolbar({
                    selectedInvoice,
                    onRefreshClick,
                    onReviewInvoiceClick,
                  }),
                }}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                pageSize={20}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {showReviewInvoiceDailog && (
        <ReviewInvoiceDialog
          invoice={invoices.find((i) => i.id === selectedInvoice.id)}
          open={showReviewInvoiceDailog}
          onClose={onReviewInvoiceDailogClose}
          onCancel={onReviewInvoiceDailogClose}
          onSubmit={onReviewInvoiceDialogSubmit}
        />
      )}
    </div>
  );
}

export default TutorInvoices;
