import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '36px 84px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  body: {
    padding: '12px 0 36px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
  },
}));
