import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginLeft: 'auto',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 16,
  },
  actionButton: {
    '&.MuiButton-root': {
      marginLeft: 16,
    },
  },
}));

export default useStyles;
