import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  items: {
    color: '#FFFFFF',
    paddingTop: 90,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 45,
    },
  },
  iconContainer: {
    marginRight: 15,
  },
  icon: {
    filter: !props?.isSelected && 'brightness(0) invert(1)',
    width: 21,
    height: 24,
    marginBottom: 5,
  },
  button: {
    borderRadius: 6,
    paddingLeft: '15px',
    paddingRight: '15px',
    margin: '5px 25px',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.12)',
    },
    '&.Mui-selected': {
      backgroundColor: '#FAFAFB',
      color: '#323232',
      '&.MuiListItemIcon-root': {
        color: '#323232',
      },
      '&:hover': {
        backgroundColor: '#FAFAFB',
      },
    },
    '&.Mui-selected .MuiListItemIcon-root': {
      color: '#323232',
    },
  },
}));

export default useStyles;
