import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  label: {
    marginTop: 16,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px dashed rgba(0, 0, 0, 0.6)',
    padding: 24,
    marginTop: 8,
    marginBottom: 8,
  },
  text: {
    marginTop: 8,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  statusMessage: {
    marginTop: 8,
  },
});

export default useStyles;
