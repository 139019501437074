import RefreshIcon from '@mui/icons-material/RefreshRounded';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  button: {
    width: '38px',
    height: '38px',
    borderRadius: '5px',
    backgroundColor: theme.palette.custom.primary1,
    border: `1px solid ${theme.palette.custom.primary1}`,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.custom.primary2,
      border: `1px solid ${theme.palette.custom.primary2}`,
    },
    '&:disabled': {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.custom.secondary3}`,
    },
  },
}));

const DataGridToolbarRefreshButton = ({ ...props }) => {
  const { classes } = useStyles();
  return (
    <IconButton className={classes.button} {...props} disableFocusRipple disableRipple>
      <RefreshIcon />
    </IconButton>
  );
};

export default DataGridToolbarRefreshButton;
