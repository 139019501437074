import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import {
  formatInvoiceDate,
  formatAmount,
} from '../../../../helpers';

const Invoice = ({ invoice }) => {
  const {
    metadata,
    id,
    createdAt,
    updatedAt,
    total,
    currency,
    status,
  } = invoice;
  const {
    tutor,
    audit,
  } = metadata;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2"><strong>Invoice Details</strong></Typography>
        <Divider />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Tutor</strong></Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          {tutor.firstName} {tutor.lastName}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Created On</strong></Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">{dayjs(formatInvoiceDate(createdAt)).format('DD-MM-YY HH:mm')}</Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="body2"><strong>Invoice Id</strong></Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">{id}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Updated On</strong></Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">{dayjs(formatInvoiceDate(updatedAt)).format('DD-MM-YY HH:mm')}</Typography>
      </Grid>
      <Grid item xs={12}><br /></Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Status</strong></Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          {status.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Total</strong></Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">{formatAmount(total, currency)}</Typography>
      </Grid>
      <Grid item xs={12}><br /></Grid>
      <Grid item xs={2}>
        <Typography variant="body2"><strong>Status Audit</strong></Typography>
      </Grid>
      <Grid item xs={10} style={{ height: 75, overflow: 'auto' }}>
        {audit?.length && audit.map((item) => (
          <Typography variant="caption" component="div" key={item.replaceAll(' ')}>
            {item}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    metadata: PropTypes.shape({
      tutor: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
      audit: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    total: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default Invoice;
