import Typography from '@mui/material/Typography';

const parentSignupTemplate = ({ classes, notificationData, userType }) => {
  const { firstName, lastName, learners } = notificationData;

  const message = userType === 'admin'
    ? (
      <>
        <strong>{firstName} {lastName}</strong> has <strong>signed up</strong> and has enroled the
        {' '}following students
        {learners && learners.map((learner) => (
          <div key={learner.id}>- <strong>{learner.firstName} {learner.lastName}</strong></div>
        ))}
      </>
    )
    : (
      <>
        Welcome to Path Education, <strong>{firstName} {lastName}</strong>.<br />

        Our team will shortly assign tutors for
        {learners.map((learner) => (
          <div key={learner.id}>- <strong>{learner.firstName} {learner.lastName}</strong></div>
        ))}
      </>
    );

  return (
    <>
      <Typography variant="body1" className={classes.author} component="div">
        <strong>Path Education</strong>
      </Typography>
      <Typography variant="body1" className={classes.message} component="div">
        {message}
      </Typography>
    </>
  );
};

export default parentSignupTemplate;
